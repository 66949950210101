import { FC, useState } from 'react';
import "../../styles/layout/footer-relatedsites.scss"
import config from '../../config';
import { openInfoseedPage, openMapprimePage, openNeospectraPage } from '../../pages/common/openSiteUtil';
const RelatedSites: FC = () => {

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleLinks = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div className="footer--links">
            <button type="button"
                onClick={toggleLinks}
                title={isExpanded ? '목록 닫힘' : '목록 열림'}
                aria-expanded={isExpanded}
                aria-controls="footerLinkMenu"
            ><span>관련기관 사이트</span> <i aria-hidden="true"></i></button>
            <ul id="footerLinkMenu">
                <li>
                    <a 
                        onClick={(e) => {
                            e.preventDefault();
                            openInfoseedPage();
                        }}>
                    영상촬영 서비스
                    </a>
                    </li>
                <li>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            openNeospectraPage();
                        }}
                    >
                        AI 데이터 생성관리 서비스
                    </a>
                </li>
                <li>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            openMapprimePage();
                        }}>
                        디지털트윈 지도 서비스
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default RelatedSites;