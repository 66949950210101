import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "../mypage/ProjectSection";
import { openInfoPage }from "../common/stringUtil";

interface project {
    id: number;
    title: string;
    count: number;
}


const InfoManage:FC<any> = ({  infoDataMap }) => {
    
    const DRN_PRJ_CNT = Number(infoDataMap?.DRN_PRJ_CNT) ? Number(infoDataMap?.DRN_PRJ_CNT) : 0;
    const PIC_PRJ_CNT = Number(infoDataMap?.PIC_PRJ_CNT) ? Number(infoDataMap?.PIC_PRJ_CNT) : 0;

    const RE_DRN_PRJ_CNT = Number(infoDataMap?.RE_DRN_PRJ_CNT) ? Number(infoDataMap?.RE_DRN_PRJ_CNT) : 0;
    
    const COM_DRN_PRJ_CNT = Number(infoDataMap?.COM_DRN_PRJ_CNT) ? Number(infoDataMap?.COM_DRN_PRJ_CNT) : 0;
    const COM_PIC_PRJ_CNT = Number(infoDataMap?.COM_PIC_PRJ_CNT) ? Number(infoDataMap?.COM_PIC_PRJ_CNT) : 0;

    
    const projects: project[] = [
        { id: 1, title: "등록된 프로젝트 수"  , count: (DRN_PRJ_CNT + PIC_PRJ_CNT) }, 
        { id: 2, title: "촬영요청"           , count: (RE_DRN_PRJ_CNT)  },
        { id: 3, title: "완료 된 프로젝트 수" , count: (COM_DRN_PRJ_CNT + COM_PIC_PRJ_CNT)  }, 

    ];

    return (
        <ProjectSections title="참여중인 프로젝트" className="col-9 col-lg-8 col-md-12 user-project" onButtonClick={() => openInfoPage("P002")} buttonClassName="icon--more" >
            <div className="user-project-list ">
                {projects.map(project => (
                    <div className="project-item" key={project.id}>
                        <div className="project-item-title">{project.title}</div>
                        <div className="project-item-count">{project.count}</div>
                    </div>
                ))}
            </div>
        </ProjectSections>
    )
}
export default InfoManage;
