import axios from 'axios';
import config from '../../config';
import { useLocation } from 'react-router-dom';


// 이메일 포맷 체크
export const isEmailFormatChk = (email : string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       
    return emailPattern.test(email);
}

// 비밀번호 형식 체크 (영문/숫자/특수문자 중 2가지 이상 조화, 10자~16자로 구성된 문자열로 구성되며, 공백 입력 불가능, 연속된 문자, 숫자 사용 불가능)
export const isPassWdFormatChk = (password : string) => {
    const passwordPattern = /^(?!.*([a-zA-Z0-9])\1{2})(?=(?:.*[A-Za-z])|(?:.*\d)|(?:.*[@$!%*?&])){2}[A-Za-z\d@$!%*?&]{10,16}$/;
       
    return passwordPattern.test(password) && !/[.,]/.test(password);
}

// byte 값을 단위변경
export const getBytesToSize = (bytes: number,unitIdx: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return `0  ${sizes[unitIdx]}`;
    
    return `${getFormatCurrencyStr((bytes / Math.pow(1024, unitIdx)).toFixed(2))} ${sizes[unitIdx]}`;
}

// byte 값을 단위변경
export const getBytesToSizeNumber = (bytes: number) : number => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 0;
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const returnV = Number((bytes / Math.pow(1024, i)).toFixed(1));

    return returnV;
}

// 추가 금액 계산
export const getAddPayNumber = (totalDataNumber: number , useDataNumber:number) : number => {
    const addPay = Math.ceil((useDataNumber - totalDataNumber)/1024/1024/1024)*10000

    return addPay;
}

export const getFormatCurrencyStr = (amount: string) => {
    return new Intl.NumberFormat('ko-KR').format(Number(amount));
}

export const getFormatCurrency = (amount: number) => {
    return new Intl.NumberFormat('ko-KR').format(amount);
}

export async function openInfoPage (pageNo: string) {
    const pageUrl = config.infoseedApiUrl + "/v1/page";
    const infoseed_token  = localStorage.getItem('infoseed_token');
    
    try {
        const response = await fetch(pageUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${infoseed_token}`,
                PAGE_CD : pageNo
            },
        });
    
        if (response.ok) {

            

            const access_token  = localStorage.getItem('access_token');

            const data = await response.json();
            
            // console.log('info api 리턴 결과:', JSON.stringify(data, null, 2));

            const form = document.createElement('form');
            form.method = 'GET';
            form.action = data.data.URL;
            form.target = 'infoseed-window';
            
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = "ptoken";
            hiddenField.value = access_token!;
            form.appendChild(hiddenField);

            const hiddenField2 = document.createElement('input');
            hiddenField2.type = 'hidden';
            hiddenField2.name = "apikey";
            hiddenField2.value = config.infoseedApiKey!;
            form.appendChild(hiddenField2);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form); // 양식 제거
            
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
    } catch(e) {
        alert("페이지 연동시 오류가 발생하였습니다.");
    }
    
}

export async function moveInfoPage (pageUrl: string) {
    alert(pageUrl);
    const access_token  = localStorage.getItem('infoseed_token');
    
    try {
        const response = await fetch(pageUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        
    
        if (response.ok) {
            const data = await response.text();
            
            // console.log('info api 리턴 결과:', JSON.stringify(data, null, 2));

            alert(pageUrl);

            const newWindow = window.open(pageUrl);
            if (newWindow) {
                newWindow.document.write('<pre>' + data + '</pre>');
                newWindow.document.close();
            }
            
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
    } catch(e) {
        alert("페이지 연동시 오류가 발생하였습니다.");
    }
    
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
