import { FC, useEffect,useState } from "react";
import ProjectSections from "./ProjectSection";
import { useNavigate } from 'react-router-dom';
import { openInfoPage , getFormatCurrency , getAddPayNumber }from "../common/stringUtil";

interface BuyProps {
    sltnId: string;
    tabSn:number;
    buyUserList:any[];
    totalDataMap:any;
}



const ProjectManage1:FC<BuyProps> = ({ sltnId , tabSn , buyUserList , totalDataMap }) => {
    const navigate = useNavigate();

    var buyUser ;
    if (buyUserList && buyUserList.length > 0) {
        buyUser = buyUserList.filter((item : any) => item.sltnId === sltnId);
    }

    const handleProjectUpgrade = () => {
        navigate('/mypage/product?tab='+tabSn);
    }
    const handleProjectSettings = () => {
        navigate('/mypage/product?tab='+tabSn);
    }
    
    const addPay = getAddPayNumber(totalDataMap.totalDataNumber,totalDataMap.useDataNumber);

    return (
        <div style={{display:"contents"}}>
            { buyUser && buyUser.length > 0 ? ( 
                <ProjectSections title={buyUser[0].prdctNm} light="제품 이용 중" className="col-3 col-lg-4 col-md-12 user-use" onButtonClick={handleProjectSettings} buttonClassName="icon--setting-white">
                    <div className="user-use-date">[구독기간] {buyUser[0].startDate} - {buyUser[0].endDate}</div>
                    <div className="user-use-progress">
                        <div className="desc"><b>{totalDataMap.useData}</b> / {totalDataMap.totalData} ( {totalDataMap.usePer} % )</div>
                        <progress value={totalDataMap.useDataNumber} max={totalDataMap.totalDataNumber}></progress>
                    </div>
                    <div className="user-use-date" style={{marginBottom:"-20px"}}>
                        [상품금액] {getFormatCurrency(buyUser[0].prdctAmt)} 원 
                        {addPay > 0 ? 
                         <b> (추가금액 : {getFormatCurrency(addPay)} 원)</b>
                         : null }
                    </div>
                    <button type="button" className="user-use-upgrade" onClick={handleProjectUpgrade}>
                        구독상품보기
                    </button>
                </ProjectSections>
            ) : 
                <ProjectSections title="" light="구매한 제품이 없습니다." className="col-3 col-lg-4 col-md-12 user-use" onButtonClick={handleProjectSettings} buttonClassName="">
                    <div className="user-use-date"></div>
                    <div className="user-use-progress"></div>
                </ProjectSections>
            }
        </div>
    )
}

export default ProjectManage1;
