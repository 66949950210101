import { FC } from 'react';
import Button from "../../components/Buttons";
import Dialog from "../../components/Dialog";


interface TestDialogProps {
isOpen: boolean;
onClose: () => void;
}

const TestDialog:FC<TestDialogProps> = ( { isOpen, onClose } ) => {
    return (
        <Dialog
        id="TestDialog"
        isOpen={ isOpen }
        title="배포 정보"
        onClose={ onClose }
        >   
            내용
         
            <div className="button-group justify-between">
                <Button
                onClick={onClose}
                size="md"
                text="취소"
                />
                <Button
                text="수정"
                size="md"
                variant="outline"
                onClick={() => {
                    // console.log("");
                }}
                />
            </div>
        </Dialog>
    )
}

export default TestDialog;