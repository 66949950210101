import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import MypageLayout from "./MypageLayout";
import TabComponent from '../../components/Tab';
import './ProductManage.scss';
import { Link } from "react-router-dom";
import Product from '../../components/Product'
import BuyList from '../../components/BuyList'
import { useNavigate } from 'react-router-dom';
import { loadTossPayments, ANONYMOUS, TossPaymentsWidgets } from "@tosspayments/tosspayments-sdk";
import { CreateBuyDto } from "../../types/CreateBuyDto";
import { postBuyAsync, getMapprimeDashboardAsync, getBuyUseAsync } from '../../modules/product';
import { getNeospectraProjectCntAsync , getNeospectraProjectStatsAsync , getNeospectraJobsStatsAsync , getNeospectraUsagesCurrentAsync 
    , getNeospectraUsagesServiesLeftAsync , getInfoseedMypageAsync
} from '../../modules/myPage';
import { useQuery } from '../common/stringUtil';
import { getBytesToSize,getFormatCurrency , getAddPayNumber } from "../../pages/common/stringUtil";

interface PageProps {
    title: string;
    pageTitle: string;
}

const tabs = [
    { id: 1, title: '영상촬영 서비스'            , control: 'content-1' , key: 'GEO_NIC' },
    { id: 2, title: 'AI 데이터 생성관리 서비스 ' , control: 'content-2'  , key: 'SIMMETA' },
    { id: 3, title: '디지털트윈 지도 서비스'     , control: 'content-3'  , key: 'MAPPRIME' },
];

interface Project {
    id: number;
    title: string;
    count: number;
}

const MypageProductManage: FC<PageProps> = ({ title, pageTitle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const query = useQuery();
    const tabSn = Number(query.get('tab')); // 쿼리 파라미터에서 id 값을 가져옵니다.
 
    const [currentTab, setCurrentTab] = useState<number>(tabs[tabSn].id);
    const [sltnId, setSltnId] = useState<string>(tabs[tabSn].key);
    const [sltnNm, setSltnNm] = useState<string>(tabs[tabSn].title);
    const [widgets, setWidgets] = useState<TossPaymentsWidgets | null>(null);
    const [projects, setProjects] = useState<Project[]>([]);
    const [buyUseYn, setBuyUseYn] = useState<string>("none");
    const [totalData, setTotalData] = useState<string>();
    const [useData, setUseData] = useState<string>();
    const [usePer, setUsePer] = useState<number>(0.0);
    const [totalDataNumber, setTotalDataNumber] = useState<number>();
    const [useDataNumber, setUseDataNumber] = useState<number>();
    
    const [buyType, setBuyType] = useState<string>("VIEW");

    // 결과 리턴값 설정
    const buyInfo = useSelector((state: RootState) => state.product.buyInfo);
    const mapprimeDashboard = useSelector((state: RootState) => state.product.dashboardInfo);
    const buyUser = useSelector((state: RootState) => state.product.buyUser);
    const neoProjCnt       = useSelector((state:RootState) => state.myPage.neoProjCnt);
    const neoProjStat      = useSelector((state:RootState) => state.myPage.neoProjStat);
	const neoJobsStat      = useSelector((state:RootState) => state.myPage.neoJobsStat);
	const neoUsagesCurrent = useSelector((state:RootState) => state.myPage.neoUsagesCurrent);
	const neoUsagesServies = useSelector((state:RootState) => state.myPage.neoUsagesServies);
	const infoseedMypage = useSelector((state:RootState) => state.myPage.infoseedMypage);

    const [createBuyDto, setCreateBuyDto] = useState<CreateBuyDto>({
        stlmAmt: 0,
        customerMobilePhone: ""
    });

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');
        if (!access_token) {
			navigate('/login', { state: { from: `/mypage/product`}});
        }
    }, []);

    const handleTabClick = (id: number, title: string) => {

        setSltnNm(title);

        setCurrentTab(id);        
    };

    useEffect(() => {
        if (currentTab) {
            setSltnId(tabs[currentTab-1].key);
        }
        
    }, [currentTab]);

    // input 객체 변경시 
    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        input.value = input.value.replace(/[^0-9]/g, '');
    };

    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCreateBuyDto((prev) => ({ ...prev, [name]: value }));
    };

    const setBuyProduct = (prdctSn: number, prdctNm: string, month: number, payment: number, widgets: TossPaymentsWidgets | null, customerKey: string) => {

        setWidgets(widgets);

        var scrtnTypeCd = "M";
        if (month == 12) {
            scrtnTypeCd = "Y";
        }

        setCreateBuyDto((prev) => ({
            ...prev,
            stlmNm: prdctNm
            , prdctSn: prdctSn
            , scrtnTypeCd: scrtnTypeCd
            , stlmAmt: payment
            , stlmCustKey: customerKey
            , cancelScrtnId: buyUser.data.data.scrtnId
        }));
    }

    const buyAct = () => {

        const access_token = localStorage.getItem('access_token');

        if (!access_token) {
            alert("로그인 후 결제가 가능합니다.");
            navigate('/login')
        }

        if (createBuyDto == null) {
            alert("구매할 상품을 선택해 주십시오.");
        } else if (createBuyDto.prdctSn == null || createBuyDto.prdctSn == 0) {
            alert("구매할 상품을 선택해 주십시오.");
        } else if (createBuyDto.stlmAmt > 0 && (createBuyDto.customerMobilePhone == null || createBuyDto.customerMobilePhone == "")) {
            alert("결제 결과를 받을 핸드폰 번호를 입력해 주십시오.");
        } else if (createBuyDto.stlmAmt == 0) {
            navigate('/contact')
        } else {

            const fetchBuyInfo = async () => {
                await dispatch(postBuyAsync.request(createBuyDto));
            };

            fetchBuyInfo();
        }
    }

    useEffect(() => {
        
        const fetchBuyInfo = async () => {
            await dispatch(getBuyUseAsync.request(sltnId));
        };

        if (sltnId) {
            fetchBuyInfo();
        }
        

    }, [sltnId]);
    
    useEffect(() => {
        var neoProjList : Project[] = [];

        if (neoProjCnt?.data?.length > 0) {
            neoProjCnt?.data?.forEach((item:any,index:number) => {
                neoProjList.push({ id:index 
                                 , title : item.category_name 
                                 , count : item.count 
                });
            });
        } else {
            neoProjList = ([
                { id: 1, title: "객체 탐지", count: 0 },
                { id: 2, title: "객체 삭제", count: 0 },
            ]
            );
        }
        
        setProjects(neoProjList);

    }, [neoProjCnt]);

    useEffect(() => {
        if (buyUser && buyUser.data) {
            if (buyUser.data.status == "error") {
                alert("구독 정보 조회시 오류 입니다.");
                navigate('/');
                return;
            }

            const access_token = localStorage.getItem('access_token');
            const userRoleCd = localStorage.getItem('userRoleCd');
    
            if (buyUser && buyUser.data && buyUser.data.data && buyUser.data.data.startDate && buyUser.data.data.startDate && buyUser.data.data.compAmt > 0 && access_token && userRoleCd == "ADMIN") {
                setBuyUseYn("block");
                setBuyType("BUY");
            } else {
                setBuyUseYn("none");
                setBuyType("VIEW");
            }
    
            const fetchBuyInfo = async () => {
                if (sltnId == "MAPPRIME" && localStorage.getItem("isMapprimeSubscribe")) {
                    await dispatch(getMapprimeDashboardAsync.request({
                        mapprimeToken: localStorage.getItem("mapprimeToken"),
                    }));
                } else if (sltnId == "SIMMETA" && localStorage.getItem("isNeospectraSubscribe")) {
    
                    await dispatch(getNeospectraProjectCntAsync.request({
                        access_token: localStorage.getItem("access_token"),
                    }));
    
                    await dispatch(getNeospectraUsagesCurrentAsync.request({
                        access_token: localStorage.getItem("access_token"),
                    }));
                } else if (sltnId == "GEO_NIC" && localStorage.getItem("isInfoseedSubscribe")) {
                    await dispatch(getInfoseedMypageAsync.request(null));
                }
            }
    
            fetchBuyInfo();

        }

    }, [buyUser]);

    useEffect(() => {
        if (sltnId == "MAPPRIME" && buyUser && buyUser.data && mapprimeDashboard && mapprimeDashboard.data && mapprimeDashboard.data.result != "error") {
            // console.log('mapprimeDashboard 리턴 결과:', JSON.stringify(mapprimeDashboard, null, 2));

            // const buyUserInfo = buyUser.filter((item : any) => item.sltnId === "GEO_NIC");

			const prdctId = (buyUser!.data!.data!) ? buyUser.data.data.prdctId : "";


			var totalData = 0;
			if (prdctId == "PRDCT_GRP001") {
				totalData = 5*1024*1024*1024;
			} else if (prdctId == "PRDCT_GRP002") {
				totalData = 50*1024*1024*1024;
			} else if (prdctId == "PRDCT_GRP003") {
				totalData = 1000*1024*1024*1024;
			} else if (prdctId == "PRDCT_GRP004") {
				totalData = 2000*1024*1024*1024;
			}

            // const totalData = Number(mapprimeDashboard.data.size.total);
            const currentData = Number(mapprimeDashboard.data.size.current);

            const projectCnt = mapprimeDashboard.data.count.project.current;
            const assetCnt = mapprimeDashboard.data.count.asset.progress + mapprimeDashboard.data.count.asset.done;

            const usePer = (currentData / totalData) * 100;
            
            setTotalData(getBytesToSize(totalData,3));  // 53687091200
            setUseData(getBytesToSize(currentData,3));  // 32212254720
            setUsePer(Number(usePer.toFixed(2)));
            setTotalDataNumber(totalData);
            setUseDataNumber(currentData);

            setProjects([
                { id: 1, title: "프로젝트 개수", count: projectCnt },
                { id: 2, title: "에셋 개수", count: assetCnt }
            ]
            );
        } else {
            setProjects([
                { id: 1, title: "프로젝트 개수", count: 0 },
                { id: 2, title: "에셋 개수", count: 0 }
            ]
            );
        }

    }, [mapprimeDashboard]);

    useEffect(() => {
        if (sltnId == "GEO_NIC" && buyUser && buyUser.data && infoseedMypage && infoseedMypage.data && infoseedMypage.data.status != "error") {
			
            const prdctId = (buyUser!.data!.data!) ? buyUser.data.data.prdctId : "";


			var totalData = 0;
			if (prdctId == "PRDCT_GRP001") {
				totalData = 0;
			} else if (prdctId == "PRDCT_GRP002") {
				totalData = 10*1024;
			} else if (prdctId == "PRDCT_GRP003") {
				totalData = 20*1024;
			} else if (prdctId == "PRDCT_GRP004") {
				totalData = 30*1024;
			}
			
			// const totalData = Number(infoseedMypage.data.data.TOTAL_MEMORY);
			const currentData = Number(infoseedMypage.data.data.USE_MEMORY);

            setTotalData(getBytesToSize(totalData*1024*1024,2));  // 53687091200
            setUseData(getBytesToSize(currentData*1024*1024,2));  // 32212254720
            setUsePer(totalData == 0 ? 0 : Math.round(currentData/totalData*100));
            setTotalDataNumber(totalData*1024*1024);
            setUseDataNumber(currentData*1024*1024);

            const DRN_PRJ_CNT = Number(infoseedMypage.data.data?.DRN_PRJ_CNT) ? Number(infoseedMypage.data.data?.DRN_PRJ_CNT) : 0;
            const PIC_PRJ_CNT = Number(infoseedMypage.data.data?.PIC_PRJ_CNT) ? Number(infoseedMypage.data.data?.PIC_PRJ_CNT) : 0;

            const RE_DRN_PRJ_CNT = Number(infoseedMypage.data.data?.RE_DRN_PRJ_CNT) ? Number(infoseedMypage.data.data?.RE_DRN_PRJ_CNT) : 0;
    
            const COM_DRN_PRJ_CNT = Number(infoseedMypage.data.data?.COM_DRN_PRJ_CNT) ? Number(infoseedMypage.data.data?.COM_DRN_PRJ_CNT) : 0;
            const COM_PIC_PRJ_CNT = Number(infoseedMypage.data.data?.COM_PIC_PRJ_CNT) ? Number(infoseedMypage.data.data?.COM_PIC_PRJ_CNT) : 0;

            setProjects([
                { id: 1, title: "등록된 프로젝트 수", count: (DRN_PRJ_CNT + PIC_PRJ_CNT) },
                { id: 2, title: "촬영요청", count: RE_DRN_PRJ_CNT },
                { id: 3, title: "완료 된 프로젝트 수", count: (COM_DRN_PRJ_CNT+COM_PIC_PRJ_CNT) },
            ]
            );

		}
    }, [infoseedMypage]);

	useEffect(() => {
        if (sltnId == "SIMMETA" && buyUser && buyUser.data && neoUsagesCurrent && neoUsagesCurrent.data && neoUsagesCurrent.data.result != "error") {
			const prdctId = (buyUser!.data!.data!) ? buyUser.data.data.prdctId : "";

			var totalData = 0;
            if (prdctId == "PRDCT_GRP001") {
                totalData = 3;
            } else if (prdctId == "PRDCT_GRP002") {
                totalData = 100;
            } else if (prdctId == "PRDCT_GRP003") {
                totalData = 300;
            } else if (prdctId == "PRDCT_GRP004") {
                totalData = 1000;
            }
			
			// const totalData = Number(neoUsagesCurrent.data?.available);
			const currentData = Number(neoUsagesCurrent.data?.used);

			if (totalData) {
                setTotalData(totalData+"GB");  // 53687091200
                setUseData(currentData+"GB");  // 32212254720
                setUsePer(Math.round(currentData/totalData*100));
                setTotalDataNumber(totalData*1024*1024*1024);
                setUseDataNumber(currentData*1024*1024*1024);
			} else {
				setTotalData("0GB");  // 53687091200
                setUseData("0GB");  // 32212254720
                setUsePer(0);
                setTotalDataNumber(0);
                setUseDataNumber(0);
			}

			

		}
    }, [neoUsagesCurrent]);

    // console.log('buyUser 리턴 결과:', JSON.stringify(buyUser.data, null, 2));

    useEffect(() => {
        // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
        // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
        try {
            // console.log('createBuyDto parameter 결과:', JSON.stringify(createBuyDto, null, 2));

            if (widgets != null) {
                if (buyInfo && buyInfo.data) {

                    if (buyInfo.data.status == "error") {
                        if (buyInfo.data.error) {
                            alert(buyInfo.data.error.message);
                            return;
                        }
                    }


                    const orderId: string | undefined = buyInfo.data.data.stlmId as string;

                    if (createBuyDto) {
                        widgets.requestPayment({
                            orderId: orderId,
                            orderName: buyInfo.data.data.stlmNm,
                            successUrl: window.location.origin + "/buySuccess",
                            failUrl: window.location.origin + "/buyFail",
                            customerEmail: buyInfo.data.data.customerEmail,
                            customerName: buyInfo.data.data.customerName,
                            customerMobilePhone: createBuyDto.customerMobilePhone?.replaceAll("-", ""),
                        });
                    }

                }
            }
        } catch (error) {
            // 에러 처리하기
            console.error(error);
        }
    }, [buyInfo]);

    return (
        <MypageLayout bannerTitle={title} pageTitle={pageTitle}>
            <div className="container">
                <TabComponent
                    tabs={tabs}
                    role='tab'
                    current={currentTab}
                    onClick={(index, tab) => handleTabClick(tab.id, tab.title)}
                />
                <div className="tabpanel" id='content-1' role='tabpanel' hidden={currentTab !== 1}>

                </div>
                <div className="tabpanel" id='content-2' role='tabpanel' hidden={currentTab !== 2}>

                </div>
                <div className="tabpanel" id='content-3' role='tabpanel' hidden={currentTab !== 3}>

                </div>

                {buyUser.loading ? (
                    <div className="no-data-wrap">
                        <p className="big-ti">lloading...</p>
                    </div>
                ) : buyUser && buyUser.data && buyUser.data.data ? (
                    <div className="mb40">
                        <div className="grid product-grid mb30">
                            <div className="col-3 col-lg-4 col-md-12">
                                <header className="col-header"><h4>{buyUser.data.data.prdctNm}<em>&nbsp;제품 이용 중</em></h4></header>
                                <div className="user-use-date">[구독기간] {buyUser.data.data.startDate} - {buyUser.data.data.endDate}</div>
                                <div className="user-use-progress">
                                    <div className="desc"><b>{useData}</b> / {totalData} ( {usePer} % )</div>
                                    <progress value={useDataNumber} max={totalDataNumber}></progress>
                                </div>
                                <div className="user-use-date" style={{marginBottom:"-20px"}}>
                                    [상품금액] {getFormatCurrency(buyUser.data.data.prdctAmt)} 원 
                                    {getAddPayNumber(totalDataNumber!,useDataNumber!) > 0 ? 
                                    <b> (추가금액 : {getFormatCurrency(getAddPayNumber(totalDataNumber!,useDataNumber!))} 원)</b>
                                    : null }
                                </div>
                            </div>   
                            <div className="col-9 col-lg-8 col-md-12">
                                <div className="user-project-list">
                                    {projects && projects.length > 0 ? (
                                        projects.map(project => (
                                            <div className="project-item" key={project.id}>
                                                <div className="project-item-title">{project.title}</div>
                                                <div className="project-item-count">{project.count}</div>
                                            </div>
                                        ))) : null}                                    
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                    <div className="no-data-wrap">
                        <p className="big-ti">현재 구매한 제품이 없습니다.</p>
                        <p className="small-ti">"<span>{sltnNm}</span>" 제품 구매 후 이용 바랍니다.</p>
                        <div className="flex justify-center">
                            <Link to={'/product'} className="button button--outline button--round button--md">제품 선택 화면으로 이동</Link>
                        </div>
                    </div>
                }

                {buyUser && buyUser.data && buyUser.data.data ? (
                    <BuyList sltnId={sltnId} />
                ) : null}

                {buyUser && buyUser.data && buyUser.data.data ? (
                    <div className="mb50">
                        <div className="product-ti border">
                            <h3>제품 목록</h3>
                        </div>
                        <Product sltnId={sltnId} compAmt={buyUser.data.data.compAmt} buyPrdctId={buyUser.data.data.prdctId} buyType={buyType} setBuyProduct={setBuyProduct} />
                    </div>
                ) : null}

                {/* 결제 UI */}
                <div style={{ display: buyUseYn }}>
                    <div id="payment-method" style={{display: createBuyDto.stlmAmt > 0 ? "" : "none" }} />
                    <div id="inputPhone" style={{display: createBuyDto.stlmAmt > 0 ? "flex" : "none" }} >
                        <div style={{ paddingLeft: "30px", paddingTop: "13px" }}>
                            <span style={{ color: "red" }}><b> * </b></span>핸드폰 번호
                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                            <input type="number" className="form-control " id="customerMobilePhone" placeholder="핸드폰 번호를 숫자만 입력해 주세요." name="customerMobilePhone" style={{ width: "300px" }} value={createBuyDto.customerMobilePhone} onChange={inputHandleChange} onKeyUp={handleKeyUp} required />
                        </div>
                    </div>
                    {/* 이용약관 UI */}
                    <div id="agreement" style={{display: createBuyDto.stlmAmt > 0 ? "" : "none" }} />

                    <div className="flex justify-center">
                        <button type="button" className="button button--outline-black button--round button--md" style={{ marginRight: createBuyDto.stlmAmt > 0 ? "0px" : "250px"  }} onClick={buyAct}>구매 하기</button>
                        <button type="button" className="button button--outline-black button--round button--md" style={{ display: "none" }}>구독 취소</button>
                    </div>
                </div>
            </div>
        </MypageLayout>

    );
};

export default MypageProductManage;
