import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { Link } from 'react-router-dom';
import Banners  from "../../containers/banner";
import SearchForm   from "../../components/Search";
import SortDisplay   from "../../components/SortDisplay";
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import Pagination from '../../components/paging';
import { getMyPageContactUsListAsync } from '../../modules/myPage';
import { useNavigate } from 'react-router-dom';
import { PotalCommDto } from '../../types/PotalCommDto';
import MypageLayout from "./MypageLayout";

interface PageProps {
    title: string;
    pageTitle:string;
}
const MypageContactUs:FC<PageProps> = ({title , pageTitle}) => {
    const navigate = useNavigate();
    const [pageingDto, setPageing] = useState<PotalCommDto>({
        currentPageNo : 1
      , pageUnit : 10
      , pageSize : 10
      , firstIndex : 0
      , totalCnt   : 0
      , firstPageNo : 1
      , lastPageNo : 1
      , prevPageNo : 0
      , nextPageNo : 0
      , listTotalCnt : 0
    });
    const dispatch = useDispatch();
    const supportInfo  = useSelector((state:RootState) => state.myPage.data);
    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy : "REG_DT"
      , currentPageNo : 1
      , pageUnit : 10
    });

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');
        if (!access_token) {
			navigate('/login', { state: { from: `/mypage/MypageContactUs`}});
        }
    }, []);


    var listTotalCnt = 0;
    var totalCnt = 0;
    var pageUnit = 10;
    var currentPageNo = 1;

    if (supportInfo.data ) {
        if (supportInfo.data.meta) {
            totalCnt = Number(supportInfo.data.meta.totalCnt);
            pageUnit = supportInfo.data.meta.pageUnit;
            currentPageNo = supportInfo.data.meta.currentPageNo;
        }
        
        if (supportInfo.data.data) {
           listTotalCnt = Number(supportInfo.data.data.length);
        }
    }

    useEffect(() => {
        if (supportInfo.data) {
            if (supportInfo.data.meta) {
                setPageing({ 
                    currentPageNo : supportInfo.data.meta.currentPageNo
                  , pageUnit : supportInfo.data.meta.pageUnit
                  , pageSize : supportInfo.data.meta.pageSize
                  , firstIndex : supportInfo.data.meta.firstIndex
                  , totalCnt   : supportInfo.data.meta.totalCnt
                  , firstPageNo : supportInfo.data.meta.firstPageNo
                  , lastPageNo : supportInfo.data.meta.lastPageNo
                  , prevPageNo : supportInfo.data.meta.prevPageNo
                  , nextPageNo : supportInfo.data.meta.nextPageNo
                  , listTotalCnt : supportInfo.data.data.length
              });
            }
        }
    },[supportInfo]);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getMyPageContactUsListAsync.request(searchParams));
        };

        fetchData();
        
        
        
      },[searchParams,dispatch]);

     const searchHandleSearch = async (newSearchParams: SearchSupportDto) => {  
        await setFormData({...searchParams, searchType : newSearchParams.searchType , searchKeyword : newSearchParams.searchKeyword , currentPageNo: 1 });
    };

    const orderHandleSearch = async (newSearchParams: SearchSupportDto) => {  
        await setFormData({...searchParams, searchOrderBy : newSearchParams.searchOrderBy , pageUnit : newSearchParams.pageUnit , currentPageNo: 1 });
    }

    const onSearch = async (pageNo:number) => {  
        await setFormData({...searchParams , currentPageNo: pageNo });
    }

    return (
        <MypageLayout bannerTitle={title} pageTitle={pageTitle}>
            <div className="row"> 
                <div className="container">
                    <SearchForm onSearch={searchHandleSearch}  groupId="INQ_TYPE_CD"/>
                    {/* search */}
                    <SortDisplay total={totalCnt} currentTotal={listTotalCnt} defSearchParams={searchParams} onSearch={orderHandleSearch} />
                    {/* board-controls */}
                    <ul className='board-table'>
                        
                        <li className="thead">
                            <span className='num md-hidden'>번호</span>
                            <span className='type md-hidden' style={{width:"25%"}}>구분</span>
                            <span className='subject' style={{width:"35.5%"}}>제목</span>
                            <span className='writer'>작성자</span>
                            <span className='date'>등록일</span>
                            <span className="files md-hidden">첨부파일</span>
                        </li>
                        {supportInfo.loading ? (
                              <li className="empty"> loading...</li>
                            ) : supportInfo.data && supportInfo.data.data && supportInfo.data.data.length > 0 ? ( 
                            supportInfo.data.data && supportInfo.data.data.map((contactUs: any, idx: number) => 
                            <li className="tbody" key={"list"+idx}>
                               <span className='num md-hidden'>{totalCnt - (pageUnit * (currentPageNo -1) + idx)}</span>

                               <span className='type md-hidden' style={{width:"20%"}}><em className='badge badge-primary'>{contactUs.inqTypeNm}</em></span>
                               <span className='subject' style={{width:"40.5%"}}>
                                
                                 {contactUs.rlsStngCd == "ALL_NO"  ? (
                                    (localStorage.getItem('userId') == contactUs.regUserId ? (
                                        <Link to={'/contactUsDetail/' + contactUs.inqSn}><i className="icon--lock"></i>{contactUs.inqTtl}</Link>
                                    ) : (
                                        <span><i className="icon--lock"></i>{contactUs.inqTtl}</span>
                                    ))
                                 ) : (
                                    <Link to={'/contactUsDetail/' + contactUs.inqSn}>{contactUs.inqTtl}123123</Link>
                                 ) }
                               </span>
                               <span className='date'>{contactUs.regUserNm}</span>
                               <span className='date'>{contactUs.regDt}</span>
                               <span className="files md-hidden">

                               {contactUs.atflOrgnlNmExt && contactUs.atflOrgnlNmExt.length > 0 ? (
                                  <i className={'icon--'+ contactUs.atflOrgnlNmExt}></i> 
                               ) : (
                                <span></span>
                               ) }
                               </span>
                            </li>)
                            ) : (<li className="empty"> 등록 된 글이 없습니다. </li>)
                         }
                    </ul>
                    <Pagination pageingDto={pageingDto} onClick={onSearch} />
                    <div className="board-foot-buttons right"><Link to={'/contactUsWrite'} className="button button--outline-black button--md"> 문의하기</Link></div>
                </div>
            </div>
        </MypageLayout>
        
    )
}

export default MypageContactUs;