import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import '../../styles/components/table.scss';
import '../../styles/components/input.scss';
import Banners  from "../../containers/banner";
import { useNavigate } from 'react-router-dom';
import { CreateContactUsDto } from '../../types/support/CreateContactUsDto';
import { UpdateContactUsDto } from '../../types/support/UpdateContactUsDto';
import { getContactUsAsync , postContactUsAsync , putContactUsAsync , deleteContactUsAsync } from '../../modules/support';
import SelectBox from '../../components/SelectBox';
import { getFileDownLoad }  from '../../api/common';
import { deleteFileAsync } from '../../modules/common';
import { getCurrentDate , getAddDays } from "../../pages/common/dateUtil";

interface WriteProps {
    title : string;
}

const ContactUsWrite:FC<WriteProps> = ({ title }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 결과 리턴값 설정
    const contactUsDetail  = useSelector((state:RootState) => state.support.data);
    const insertResult     = useSelector((state:RootState) => state.support.contactUsInsert);
    const updateResult     = useSelector((state:RootState) => state.support.contactUsUpdate);
    const delFileResult    = useSelector((state:RootState) => state.common.delFile);

    // 변수 선언
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'doc', 'hwp', 'pdf', 'docx', 'pptx', 'hwpx']; // 허용할 파일 확장자
    const [action, setAction] = useState<string>('');
    const [inqSn, setInqSn] = useState<number>(0);
    const [fileName , setFileName]= useState<string>('');
    const [userName , setUserName]= useState<string>('');
    
    const [saveParams, setFormData] = useState<CreateContactUsDto>({
        inqTypeCd: ""
      , inqTtl: ""
      , inqCn: ""
      , rlsStngCd:"ALL_OK"
      , regDt :  getCurrentDate("YYYY.MM.DD")
    });
    
    const [updateParams, updateFormData] = useState<UpdateContactUsDto>({
        inqSn:0
      , inqTypeCd: ""
      , inqTtl: ""
      , inqCn: ""
      , rlsStngCd:""
    });

    

    
    // 화면 접근시 처리 로직 ( 수정 으로 접근시 조회)
    useEffect(() => {
        const fetchSearchData = async () => {
        const currentPath = window.location.pathname.split('/'); // 경로
        const userName  = localStorage.getItem('userName');
        if (userName) {
            setUserName(userName);
        } else {
            alert("로그인 후 이용이 가능합니다.");
            navigate('/login', { state: { from: `/contactUsWrite`}});
        }
        
        if (currentPath.length > 1) {
            const inqSn = currentPath[currentPath.length-1];
            
            if (Number(inqSn)) {
                setInqSn(Number(inqSn));

                await dispatch(getContactUsAsync.request(Number(inqSn)));
            }
        }
        };

        fetchSearchData();

      },[]);

    // 수정 으로 접근시 데이터 조회후 호출
    useEffect(() => {
        if (contactUsDetail.data && contactUsDetail.data.data && inqSn > 0) {

            if (contactUsDetail.data.data) {
                // console.log('조회 결과:', JSON.stringify(contactUsDetail.data.data[0], null, 2));
                setFormData((prev) => ({ ...prev, ...contactUsDetail.data.data }));
                setFileName(contactUsDetail.data.data.atflOrgnlNm);
            }
        }
    },[contactUsDetail]);

    // 파일 업로드 클릭시 
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            const validFiles = selectedFiles.filter((file) => {
                const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
                return allowedExtensions.includes(fileExtension.toLowerCase());
            });

            if (validFiles.length === 0) {
                alert(`허용되지 않는 파일 형식입니다.  'jpg', 'jpeg', 'png', 'doc', 'hwp', 'pdf', 'docx', 'pptx', 'hwpx' 파일만 업로드 가능합니다.`);
                event.target.value = "";
                setFileName("");
                setFormData((prev) => ({ ...prev, fileList: [] }));
                return;
            } else {
                
                setFormData((prev) => ({ ...prev, fileList: validFiles }));
                setFileName(validFiles[0].name);
            }
        }
    };

    // input,textarea 객체 변경시 
    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // radio 버튼 변경시
    const handleRadioChange = (id : string , value: string) => {
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handleSelectChange = (params : any , value: string) => {
        setFormData((prev) => ({ ...prev, inqTypeCd : value })); // 선택된 값 업데이트
    };

    // Validation Check
    const inputCheck = async () => {
        var result = true;
        var resultMsg = "";

        if (!saveParams.inqTypeCd) {
            if (resultMsg != "") {
                resultMsg += "\n";   
            }

            resultMsg = '문의항목을 선택해 주십시오.';
            result = false;
        }

        if (!saveParams.inqTtl) {
            if (resultMsg != "") {
                resultMsg += "\n";   
            }
            resultMsg +='문의 제목을 입력해 주십시오.';
            result = false;
        }

        if (!saveParams.inqCn) {
            if (resultMsg != "") {
                resultMsg += "\n";   
            }
            resultMsg +='문의 내용을 입력해 주십시오.';
            result = false;
        }

        if (!result) {
            alert(resultMsg);
        }

        return result;
    }

    // 저장 버튼 클릭시
    const onSave = async () => {
        if (await inputCheck()) {

            await dispatch(postContactUsAsync.request(saveParams));
            await setAction("INSERT");            
        }
    }

    // 저장 처리 결과 후처리
    useEffect(() => {
        if (action == "INSERT") {
            if (insertResult && insertResult.data) {
                const status = insertResult.data.status;
        
                if (status == "error") {
                    alert(insertResult.data.error.message);
                } else if (status == "success") {
                    alert("저장 되었습니다.");
                    setAction("");
                    navigate('/contact');
                }
            }
        }
        
    },[insertResult]);

    // 취소 버튼 클릭시
    const oncancel = async () => {
        navigate(-1);
    }

    // 파일 다운로드 버튼 클릭시
    const handleFileDownClick = (inqSn : number , atflSn : number , event: React.MouseEvent<HTMLButtonElement> ) => {
        // e.target.attributes();
 
        getFileDownLoad('contactUs' , inqSn , atflSn );
    }

    // 파일 삭제 버튼 클릭시
    const handleFileDelClick = (inqSn : number , atflSn : number , event: React.MouseEvent<HTMLButtonElement> ) => {
        const confirmDelete = window.confirm("정말로 삭제하시겠습니까?");
        const delFileParam = {
            fileType : "contactUs"
          , groupId : inqSn
          , fileId : atflSn
        }
        if (confirmDelete) {
             dispatch(deleteFileAsync.request(delFileParam));
             setAction("DEL_FILE");
        } 
    }

    // 파일 삭제 후처리
    useEffect(() => {
        if (action == "DEL_FILE") {
            if (delFileResult && delFileResult.data) {
                const status = delFileResult.data.status;
        
                if (status == "error") {
                    alert(delFileResult.data.error.message);
                } else if (status == "success") {
                    alert("삭제 되었습니다.");
                    setAction("");
                    navigate(0);
                }
            }
        }
        
    },[delFileResult]);

    // 수정 버튼 클릭시 저장 DTO 값을 수정 DTO 로 복사 한다.
    const onUpdate = async () => {
        if (await inputCheck()) {
            if (inqSn > 0) {

                await updateFormData((prev) => ({ ...prev
                    , inqSn : inqSn 
                    , inqTypeCd : saveParams.inqTypeCd 
                    , inqTtl : saveParams.inqTtl
                    , inqCn : saveParams.inqCn
                    , rlsStngCd : saveParams.rlsStngCd
                }));    

                if (saveParams.fileList) {
                    await updateFormData((prev) => ({ ...prev, fileList   : saveParams.fileList}));
                }

                setAction("UPDATE");
            };
        }
    }

     // 수정 후처리
     useEffect(() => {
        //console.log('조회 결과3:', JSON.stringify(updateParams, null, 2));
        if (inqSn > 0) {
            dispatch(putContactUsAsync.request(updateParams));
        }
    },[updateParams]);

    // 수정 후처리
    useEffect(() => {
        if (action == "UPDATE") {
            if (updateResult && updateResult.data) {
                const status = updateResult.data.status;
        
                if (status == "error") {
                    alert(updateResult.data.error.message);
                } else if (status == "success") {
                    alert("수정 되었습니다.");
                    setAction("");
                    navigate(0);
                }
            }
        }
        
    },[updateResult]);
     

    

    // 첨부파일 HTML 생성
    const fileList = [];

    if (inqSn > 0 && contactUsDetail.data && contactUsDetail.data.data && contactUsDetail.data.data.atflOrgnlNm) {
        fileList.push(
            <tr>
                <th >첨부파일</th>
                <td colSpan={3}>
                    <span style={{top: "39%" , position : "sticky"}}>
                        {contactUsDetail.data.data.atflOrgnlNm}
                    </span>
                    <button type='button' className='button button--sm button--outline-black' style={{marginLeft:"10px" , marginRight:"10px"}}   title='파일 삭제' onClick={(e)=>{handleFileDelClick( inqSn , 0 , e )}}><i className='icon--download' aria-hidden='true' ></i>삭제</button>
                    <button type='button' className='button button--sm button--outline-black' title='파일 다운로드' onClick={(e)=>{handleFileDownClick( inqSn , 0 , e )}}><i className='icon--download' aria-hidden='true' ></i>다운로드</button>
                </td>
                
            </tr>
        );
    } 

    // 하단 버튼 HTML 생성
    const actBtnHtml = []; 
    if (inqSn > 0) {
        if (contactUsDetail.data && contactUsDetail.data.data && contactUsDetail.data.data.regUserId == localStorage.getItem('userId')) {
            actBtnHtml.push(
                <div className='board-foot-buttons' style={{justifyContent: 'flex-end',gap:"10px"}}>
                    <button type='button' className='button button--sm button--outline-black'   onClick={oncancel}>취소하기</button>
                    <button type='button' className='button button--sm button--primary'         onClick={onUpdate}>수정하기</button>
                </div>
            );
        }
    } else {
        actBtnHtml.push(
        <div className='board-foot-buttons' style={{justifyContent: 'flex-end',gap:"10px"}}>
            <button type='button' className='button button--sm button--outline-black' onClick={oncancel}>취소하기</button>
            <button type='button' className='button button--sm button--primary' onClick={onSave}>등록하기</button>
        </div>
        );
    }


    return (
        <div className='board'>
            <Banners title={title} />
        <div className="board-write">
            <div className="row">
                <div className="container">
                    <div className="table table-write">
                        <table>
                            <tbody>
                                <tr>
                                    <th scope='row' style={{width:"15%"}}>작성자</th>
                                    <td style={{width:"35%"}}>{userName}</td>
                                    <th scope='row' style={{width:"15%"}}>작성일</th>
                                    <td style={{width:"35%"}}>{saveParams.regDt}</td>
                                </tr>
                                <tr>
                                    <th scope='row'>문의 항목</th>
                                    <td colSpan={3}>
                                    <SelectBox 
                                        objectId="searchType"
                                        cdGrpId="INQ_TYPE_CD"
                                        onChange={handleSelectChange}
                                        params={null}
                                        defValue={saveParams.inqTypeCd}
                                        />
                                    </td>
                                </tr>
                                <tr> 
                                    <th scope='row'>제목</th>
                                    <td colSpan={3}><input type="text" id="inqTtl" name="inqTtl" value={saveParams.inqTtl} className='form-control block' title='문의 제목을 입력하세요' placeholder='문의 제목을 입력하세요.' onChange={inputHandleChange} /></td>
                                </tr>
                                <tr>
                                    <th scope='row'>내용</th>
                                    <td colSpan={3}>
                                        <textarea id="inqCn" name="inqCn" value={saveParams.inqCn}  className='form-control block' title='문의 내용을 입력' placeholder='문의 내용을 입력하세요.' onChange={inputHandleChange}></textarea>
                                    </td>
                                </tr>
                                {fileList}
                                <tr>
                                    <th scope='row'>파일 첨부</th>
                                    <td colSpan={3}>
                                        <div className="guidelines-txt">※ 첨부파일은 'jpg', 'jpeg', 'png', 'doc', 'hwp', 'pdf', 'docx', 'pptx', 'hwpx' 파일만 업로드 가능. 파일용량은 200MB 이하만 가능합니다.</div>
                                        <div className="form-upload">
                                            <input type="file" id='upload-file' onChange={handleFileChange} />
                                            <input type="text" className='form-control' value={fileName}  readOnly style={{width:"390px"}} />
                                            <label htmlFor="upload-file" className='button button--sm button--outline-black'>파일첨부</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope='row'>공개 설정</th>
                                    <td colSpan={3}>
                                        <div className="form-radio-group _row">
                                            <label className='label-radio'>  
                                                <input type="radio" name='rlsStngCd' checked={saveParams.rlsStngCd === "ALL_OK"} onClick={() => handleRadioChange("rlsStngCd" , "ALL_OK")}/>
                                                <i></i>
                                                <span>전체 공개</span>
                                            </label>
                                            <label className='label-radio'>
                                                <input type="radio" name='rlsStngCd' checked={saveParams.rlsStngCd === "ALL_NO"} onClick={() => handleRadioChange("rlsStngCd" , "ALL_NO")}/>
                                                <i></i>
                                                <span>비공개</span>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {actBtnHtml}

                </div>
            </div>
            
        </div>
        </div>
    )
}

export default ContactUsWrite;