import axios from 'axios';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import {PotalCommDto} from '../types/PotalCommDto';
import config from '../config';

interface ApiListResponse {
  status: string;
  data?: any[];
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface ApiResponse {
  status: string;
  data?: any;
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface SuccessResponse<T> {
  status: 'success';
  data: T;
}

interface ErrorResponse {
  status: 'error';
  error: {
    code: string;
    message: string;
  };
}


export async function getComboList(searchCommonDto: any): Promise<any | null> {
  try {
    const response = await axios.get<Promise<ApiListResponse> | null>(
      `${config.portalApiUrl}/comm/combo`,
      searchCommonDto
    );

    return response.data;
  } catch (error) {
    console.error('Failed to create user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create user',
      },
    };
  }
};

export async function getFileDownLoad(fileType : string , groupId : number , fileId : number): Promise<void | null> {

  window.location.href = `${config.portalApiUrl}/common/fileDownload?fileType=${fileType}&groupId=${groupId}&fileId=${fileId}`;
  
}

export async function deleteFile(searchCommonDto: any): Promise<any | null> {
  try {
    const access_token  = localStorage.getItem('access_token');
    const response = await axios.delete<Promise<ApiResponse> | null>(
      `${config.portalApiUrl}/common/deleteFile`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params : searchCommonDto 
      }
      
    );

    // console.log('삭제 결과 api:', JSON.stringify(response.data, null, 2));

   //  const response = await axios.post(`${config.portalApiUrl}/notices`, formData);
    return response.data;
  } catch (error) {
    // console.log('저장 결과 error api:', JSON.stringify(error, null, 2));
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete file',
      },
    };
  }
};


