import { FC, useState, ChangeEvent, FormEvent, useEffect } from 'react';
import RadioButton from "../../components/label";
import Buttons from "../../components/Buttons";
import Feedback from "../../components/Feedbacks";
import '../../styles/components/icon.scss';
import '../../styles/components/input.scss';
import './UserInfoForm.scss';
import { UserDto } from '../../types/UserDto';
import { checkUserId, createUser } from '../../api/users';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import Dialog from "../../components/Dialog";
import Pagination from '../../components/paging';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { findOgnzListAsync } from '../../modules/ognz';
import { OgnzDto, SelectOgnzDto } from '../../types/OgnzDto';
import { PotalCommDto } from '../../types/PotalCommDto';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';

interface UserInfoProps {
    userDto: UserDto;
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    nextStep: (userName: string) => void;
    prevStep: () => void;
}
const UserInfoForm: FC<UserInfoProps> = ({ userDto: userDto, handleChange, nextStep, prevStep }) => {
    const dispatch = useDispatch();
    const [idCheckStatus, setIsIdChecked] = useState<string>('');
    const [passwordFeedback, setPasswordFeedback] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const [passwordMatchFeedback, setPasswordMatchFeedback] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const [NewDialog, setIsNewProjectCreateOpen] = useState(false);
    const ognz = useSelector((state: RootState) => state.ognz.data);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [searchParams, setFormData] = useState<SelectOgnzDto>({
        searchOrderBy: "REG_DT"
        , currentPageNo: 1
        , pageUnit: 10
        , searchKeyword: ''
    });

    const onSearch = async (pageNo: number) => {
        const newParams = { ...searchParams, currentPageNo: pageNo };
        setFormData(newParams); // 상태 업데이트
        dispatch(findOgnzListAsync.request(newParams)); // API 호출
    }

    const validatePassword = (password: string) => {
        // 비밀번호 형식 체크 (대소문자, 숫자, 특수문자 조합 9~12자리, '.', ',' 제외)
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+=-]).{10,16}$/;
        if (passwordPattern.test(password) && !/[.,]/.test(password)) {
            setPasswordFeedback({ type: 'success', message: '비밀번호 형식이 올바릅니다.' });
        } else {
            setPasswordFeedback({ type: 'error', message: '비밀번호 형식에 맞지 않습니다.' });
        }
    };

    const checkPasswordMatch = (password: string, rePassword: string) => {
        if (password === rePassword) {
            setPasswordMatchFeedback({ type: 'success', message: '비밀번호가 일치합니다.' });
        } else {
            setPasswordMatchFeedback({ type: 'error', message: '비밀번호가 일치하지 않습니다.' });
        }
    };

    const handleRegisterClick = async () => {
        // 필수 입력 체크
        const requiredFields: (keyof UserDto)[] = ['userId', 'userName', 'email', 'password', 'rePassword', 'ognzId'];
        for (const key of requiredFields) {
            if (userDto[key] === '' || userDto[key] === null) {
                alert(`모든 필수 입력란을 채워주세요. ${key}`);
                return;
            }
        }

        // 이메일 형식 체크
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(userDto.email)) {
            alert('올바른 이메일 형식을 입력하세요.');
            return;
        }

        // 비밀번호 형식 체크 (영문, 숫자, 특수문자 2가지 조합 10~16자리)
        const passwordPattern = /^(?!.*([a-zA-Z0-9])\1{2})(?=(?:.*[A-Za-z])|(?:.*\d)|(?:.*[@$!%*?&])){2}[A-Za-z\d@$!%*?&]{10,16}$/;
        if (!passwordPattern.test(userDto.password)) {
            alert('비밀번호는 영문, 숫자, 특수문자 조합으로 9~30자리여야 합니다.');
            return;
        }

        // 비밀번호 일치 여부 확인
        if (userDto.password !== userDto.rePassword) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        if (idCheckStatus !== 'success') {
            alert('아이디 중복 확인을 해주세요.');
            return;
        }

        try {
            const response = await createUser(userDto);
            if (response.status === 'success') {
                alert('회원가입이 완료되었습니다.');
                nextStep(userDto.userName);
            } else {
                alert(`회원가입 실패: ${response.error.message}`);
            }
        } catch (error) {
            console.error('회원가입 중 오류가 발생했습니다:', error);
            alert('회원가입 중 오류가 발생했습니다.');
        }

    }

    const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...searchParams,
            searchKeyword: e.target.value,
        });
    };

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // 폼 제출 시 페이지 리로드 방지
        const newParams = { ...searchParams, currentPageNo: 1 };
        setFormData(newParams); // 상태 업데이트
        dispatch(findOgnzListAsync.request(newParams)); // API 호출
    };

    const handleIdcheckDuplicate = async () => {
        try {
            if (userDto.userId === '') {
                alert('아이디가 입력되지 않았습니다.');
            } else {
                const status = await checkUserId(userDto.userId);
                // await console.log(status);
                if (status === 'success') {
                    alert('사용 가능한 아이디입니다.');
                    setIsIdChecked('success');
                } else {
                    alert('아이디가 이미 존재합니다.');
                    setIsIdChecked('error');
                }
            }
        } catch (error) {
            console.error('Error checking user ID:', error);
            alert('아이디 확인 중 오류가 발생했습니다.');
        }
    }

    const handleGetOgnzList = async (page: string = "1", limit: string = "10") => {
        // dispatch(getOgnzListAsync.request({page, limit}))
    }

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        handleChange(e);
        const { name, value } = e.target;

        if (name === 'userId') {
            setIsIdChecked('');
        }

        if (name === 'password') {
            validatePassword(value);
            checkPasswordMatch(value, userDto.rePassword!);
        }

        if (name === 'rePassword') {
            checkPasswordMatch(userDto.password, value);
        }
    };

    const handleSelectOgnz = (ognaDto: OgnzDto) => {
        // `handleChange` 함수를 사용하여 `userDto` 업데이트
        handleChange({
            target: {
                name: 'ognzId',
                value: ognaDto.ognzId,
            },
        } as ChangeEvent<HTMLInputElement>);

        handleChange({
            target: {
                name: 'ognzName',
                value: ognaDto.ognzName,
            },
        } as ChangeEvent<HTMLInputElement>);

        closeDialog(); // Dialog 닫기
    };

    const openDialog = () => {
        setIsNewProjectCreateOpen(true);
    };
    const closeDialog = () => {
        setIsNewProjectCreateOpen(false);
    };

    useEffect(() => {
        dispatch(findOgnzListAsync.request(searchParams));
    }, [])

    useEffect(() => {
        // console.log(ognz.data);
    }, [ognz])

    useEffect(() => {
        dispatch(findOgnzListAsync.request(searchParams));
    }, [dispatch]);

    return (
        <div className='register-form'>
            <div className="row">
                <div className="container">
                    <form>
                        <section className="register-form-section">
                            <header>
                                <h3>기본 정보 입력</h3>
                                <p className="required"><i className='icon--check'></i>전체 항목 필수 입력 사항입니다.</p>
                            </header>
                            <div className="form-group">
                                <div className='regi-input'>
                                    <label className='label' htmlFor="userName">이름</label>
                                    <input type="text" className="form-control block" id="userName" placeholder="이름을 입력해 주세요." name="userName" value={userDto.userName} onChange={handleFieldChange} required />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <div className="labeling">회원 유형</div>
                                <div className='form-radio-group'>
                                    <RadioButton
                                        label="프로젝트 개발자"
                                        name="role"
                                        value="프로젝트 관리자"
                                        checked={selectedValue === '프로젝트 관리자'}
                                        onChange={handleRadioChange}
                                    />
                                    <RadioButton
                                        label="일반 사용자"
                                        name="role"
                                        value="일반 사용자"
                                        checked={selectedValue === '일반 사용자'}
                                        onChange={handleRadioChange}
                                    />
                                    <RadioButton
                                        label="열람자"
                                        name="role"
                                        value="열람자"
                                        checked={selectedValue === '열람자'}
                                        onChange={handleRadioChange}
                                    />
                                </div>
                            </div> */}

                            <div className="form-group">
                                <label className='label' htmlFor="userId">아이디</label>
                                <div className='regi-input'>
                                    <input type="text" className="form-control block" id="userId" placeholder="아이디을 입력해 주세요." name="userId" value={userDto.userId} onChange={handleFieldChange} required />
                                    <Buttons text="중복확인" variant="outline-black" size="sm" onClick={handleIdcheckDuplicate} />
                                    {idCheckStatus && (
                                        idCheckStatus === 'success' ? (
                                            <Feedback type="success" message="사용 가능한 아이디입니다." />
                                        ) : (
                                            <Feedback type="error" message="아이디가 이미 존재합니다." />
                                        )
                                    )}
                                </div>
                            </div>

                            <div className="form-group">
                                <label className='label' htmlFor="userPassword">비밀번호</label>
                                <div className="label-desc">영문, 숫자, 특수문자 중 2가지 이상 조합, 10자~16자로 구성된 문자열로 구성되며, 공백 입력 불가능, 연속된 문자, 숫자 사용 불가능</div>
                                <div className='regi-input'>
                                    <input
                                        type="password"
                                        className="form-control block"
                                        id="userPassword"
                                        placeholder="영문, 숫자, 특수문자 중 2가지 이상 조합, 10자~16자리"
                                        name="password"
                                        value={userDto.password}
                                        onChange={handleFieldChange}
                                        required
                                    />
                                    {passwordFeedback && (
                                        <Feedback type={passwordFeedback.type} message={passwordFeedback.message} />
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className='label' htmlFor="userRePassword">비밀번호 확인</label>
                                <div className='regi-input'>
                                    <input
                                        type="password"
                                        className="form-control block"
                                        id="userRePassword"
                                        placeholder="영문, 숫자, 특수문자 중 2가지 이상 조합, 10자~16자리"
                                        name="rePassword"
                                        value={userDto.rePassword}
                                        onChange={handleFieldChange}
                                        required
                                    />
                                    {passwordMatchFeedback && userDto.rePassword && (
                                        <Feedback type={passwordMatchFeedback.type} message={passwordMatchFeedback.message} />
                                    )}
                                </div>
                            </div>

                            <div className="form-group">
                                <label className='label' htmlFor="userEmail">이메일</label>
                                <div className='regi-input'>
                                    <div className='form-email'>
                                        <input type="text" className="form-control block" id="userEmail" name="email" value={userDto.email} onChange={handleFieldChange} placeholder="email@example.com" required />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="form-group">
                                <label className='label' htmlFor="userTelfir">휴대전화</label>
                                <div className='regi-input'>
                                    <div className='form-tel'>
                                        <select name="" id="userTelfir" className="form-select block">
                                            <option value="">선택</option>
                                            <option>010</option>
                                            <option>070</option>
                                            <option>02</option>
                                        </select>
                                        <span className="hipen">-</span>
                                        <input type="text" className="form-control block" required />
                                        <span className="hipen">-</span>
                                        <input type="text" className="form-control block" required />
                                    </div>
                                </div>
                            </div> */}
                            {!query.get('key') ? (
                                <div className="form-group">
                                    <label className='label' htmlFor="userOrgan">기관</label>
                                    <div className='regi-input'>
                                        <input type="text" className="form-control block" id="userOrgan" value={userDto.ognzName} placeholder="기관 이름" disabled />
                                        <Buttons text="기관 선택" variant="outline-black" size="sm" onClick={handleGetOgnzList} />
                                        <button type="button" onClick={openDialog} className="button button--sm button--outline-black">기관 선택</button>
                                    </div>
                                </div>
                            ) : (
                                <div className="form-group">
                                    <label className='label' htmlFor="userOrgan">기관</label>
                                    <div className='regi-input'>
                                        <input type="text" className="form-control block" id="userOrgan" value={userDto.ognzName} disabled />
                                    </div>
                                </div>
                            )}
                        </section>
                        {/* <section className="register-form-section">
                            <header>
                                <h3>구독 정보</h3>
                            </header>

                            <div className="form-group">
                                <label className='label' htmlFor="userTeam">소속부서</label>
                                <div className='regi-input'>
                                    <input type="text" className="form-control block" id="userTeam" title='소속부서 선택' required />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className='label' htmlFor="userWork">담당업무</label>
                                <div className='regi-input'>
                                    <select name="" id="userWork" className='form-select block' title='담당업무 선택'>
                                        <option value="">선택</option>
                                    </select>
                                </div>
                            </div>
                        </section> */}

                        <div className="button-group center">
                            <button type='button' className='button button--md button--outline-black' onClick={prevStep}>이전 단계</button>
                            <button type='button' className='button button--md button--primary' onClick={handleRegisterClick}>다음 단계</button>
                        </div>
                    </form>

                    <Dialog id="userOrganModal" isOpen={NewDialog} title="기관 선택" onClose={closeDialog}>
                        <div className="form-search mb">
                            <form className="form-fieldset" onSubmit={handleSearchSubmit}>
                                <fieldset>
                                    <legend className="sr-only">프로젝트 검색</legend>
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            id="searchKeyword"
                                            name="searchKeyword"
                                            className="form-control"
                                            title="검색어 입력"
                                            placeholder="기관명을 입력하세요"
                                            value={searchParams.searchKeyword}
                                            onChange={handleSearchInputChange}
                                        />
                                        <button type="submit" className="button button--primary button--sm  ">검색</button>
                                    </div>
                                </fieldset>
                            </form>
                        </div>

                        <div className="board-controls user-controls">
                            <span className="board-display"><span>총 게시물</span> <em>({ognz.data?.data?.length}건</em>/{Number(ognz.data?.meta?.totalCnt).toLocaleString()}건)</span>
                        </div>
                        <ul className='board-table user-tbl'>
                            <li className="thead">
                                <span className='col-th'>기관 아이디</span>
                                <span className='col-th'>기관명</span>
                            </li>
                            {
                                ognz.data?.data?.length > 0 ? (
                                    ognz.data.data.map((ognaDto: OgnzDto, idx: number) => {
                                        return (
                                            <li
                                                className="tbody"
                                                key={idx}
                                                onClick={() => handleSelectOgnz(ognaDto)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <span className='col-td'>{ognaDto.ognzId}</span>
                                                <span className='col-td'>{ognaDto.ognzName}</span>
                                            </li>
                                        )
                                    })
                                ) : (
                                    <div>
                                        <LoadingSpinner />
                                    </div>
                                )}
                        </ul>
                        <Pagination key="1" pageingDto={ognz.data?.meta} onClick={onSearch} />
                    </Dialog>

                </div>
            </div>
        </div>
    )
}

export default UserInfoForm;