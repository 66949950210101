import axios from 'axios';
import { UserDto } from '../types/UserDto';
import { CreateUserDto } from '../types/CreateUserDto';
import config from '../config';

interface ApiResponse {
  status: string;
  data?: UserDto;
  error?: {
    code: string;
    message: string;
  };
}

interface SuccessResponse<T> {
  status: 'success';
  data: T;
  meta: any;
}

interface ErrorResponse {
  status: 'error';
  error: {
    code: string;
    message: string;
  };
}

export async function getUser(userId: string): Promise<UserDto | null> {
  try {
    const response = await axios.get<ApiResponse>(
      `${config.portalApiUrl}/user/${userId}`
    );
    if (response.data.status === 'success') {
      return response.data.data || null;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    return null;
  }
}

export async function getUsers(name: any) {
  if (name === null) return null;
  const response = await axios.get<UserDto[] | null>(
    `${config.portalApiUrl}/user/${name ? name : 'list'}`
  );
  return response.data;
}

export const createUser = async (
  createUserDto: UserDto
): Promise<SuccessResponse<UserDto> | ErrorResponse> => {
  try {
    const response = await axios.post<SuccessResponse<UserDto> | ErrorResponse>(
      `${config.portalApiUrl}/user`,
      createUserDto
    );
    return response.data;
  } catch (error) {
    console.error('Failed to create user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to create user',
      },
    };
  }
};

export async function checkUserId(userId: string): Promise<string | null> {
  try {
    const response = await axios.post<ApiResponse>(
      `${config.portalApiUrl}/user/check/check-id`,
      { id: userId } // POST 요청의 본문에 데이터를 포함
    );

    if (response.data.status === 'success') {
      // console.log('success');
      return response.data.status || null;
    } else if (response.data.status === 'error') {
      // console.log(response.data.error?.message);
      return null;
    } else {
      // console.log('else');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    return null;
  }
}

export async function findUserId({ name, email }: { name: string, email: string }): Promise<string | null> {
  try {
    const response = await axios.post<ApiResponse>(
      `${config.portalApiUrl}/user/check/userid`,
      { name: name, email: email } // POST 요청의 본문에 데이터를 포함
    );

    if (response.data.status === 'success' && response.data.data?.userId) {
      // console.log('success');
      return response.data.data.userId || null; // userId를 반환
    } else if (response.data.status === 'error') {
      // console.log(response.data.error?.message);
      alert(`일치하는 정보가 없습니다.`);
      return null;
    } else {
      // console.log('else');
      return null;
    }
  } catch (error) {
    console.error('Error find user:', error);
    return null;
  }
}

export async function getPassword({ name, email, id }: { name: string, email: string, id: string }): Promise<SuccessResponse<{ password: string }> | ErrorResponse> {
  try {
    const response = await axios.post<SuccessResponse<{ password: string }> | ErrorResponse>(
      `${config.portalApiUrl}/user/check/password`,
      { 
        name: name,
        id: id, 
        email: email 
      } // POST 요청의 본문에 데이터를 포함
    );

    if (response.data.status === 'success' && response.data) {
      alert(`임시 비밀번호가 메일로 발송되었습니다.`);
      window.location.href = '/login';
      return response.data; // userId를 반환
    } else if (response.data.status === 'error') {
      // console.log(response.data.error?.message);
      alert(`일치하는 정보가 없습니다.`);
      return {
        status: 'error',
        error: {
          code: '',
          message: response.data.error?.message
        }
      };
    } else {
      // console.log('else');
      return {
        status: 'error',
        error: {
          code: '',
          message: ''
        }
      };
    }
  } catch (error) {
    console.error('Error find password:', error);
    return {
      status: 'error',
      error: {
        code: '',
        message: 'Error find password'
      }
    };
  }
}

export async function deleteUser(userId: string[]): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await axios.delete<Promise<SuccessResponse<null> | ErrorResponse>>(
      `${config.portalApiUrl}/user`,
      {
        params: { id: userId }
      }
    );

    if ((await response.data).status === 'success') {
      // console.log('success');
      return response.data; // userId를 반환
    } else if ((await response.data).status === 'error') {
      // console.log((await response.data));
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete user',
        },
      };
    } else {
      // console.log('else');
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete user',
        },
      };
    }
  } catch (error) {
    console.error('Error delete user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete user',
      },
    };
  }
}