import { getProfile } from "../../api/authuser";
import config from "../../config";

// 공통 폼 제출 함수
const submitForm = (
    url: string,
    target: string,
    inputs: { [key: string]: string | null }
) => {
    // 항상 최신 access_token과 refresh_token을 동적으로 가져옴
    const dynamicInputs = {
        ...inputs,
        t: localStorage.getItem("access_token"), // 최신 access_token
        r: localStorage.getItem("refresh_token"), // 최신 refresh_token
    };

    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;
    form.target = target;

    // Hidden Input 추가
    Object.entries(dynamicInputs).forEach(([name, value]) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value || "";
        form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};

// 공통 서비스 페이지 열기 함수
const openServicePage = async (
    serviceName: string,
    windowUrl: string,
    formActionUrl: string,
    formInputs: { [key: string]: string | null },
    windowName: string
) => {
    const isSubscribed =
        localStorage.getItem(`is${serviceName}Subscribe`) === "true";
    if (!isSubscribed) {
        alert("구독하지 않은 서비스입니다.");
        return;
    }

    const result = await getProfile();
    if (!result) {
        alert("사용자 정보를 가져올 수 없습니다.");
        return;
    }

    const updatedFormInputs = { ...formInputs };
    Object.keys(updatedFormInputs).forEach((key) => {
        if (key === "t" || key === "access_token" || key === "ptoken") {
            updatedFormInputs[key] = localStorage.getItem("access_token");
        } else if (key === "r" || key === "refresh_token") {
            updatedFormInputs[key] = localStorage.getItem("refresh_token");
        }
    });

    if (serviceName === "Infoseed") {
        // API 호출 및 동적 URL 열기
        try {
            const response = await fetch(formActionUrl, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("infoseed_token")}`,
                    PAGE_CD: "P001",
                },
            });

            if (response.ok) {
                const data = await response.json();
                // console.log("info api 리턴 결과:", JSON.stringify(data, null, 2));

                const serviceWindow = window.open(data.data.URL, windowName);
                if (!serviceWindow || serviceWindow.closed) {
                    alert("팝업 차단이 활성화되어 있습니다. 팝업을 허용해 주세요.");
                }
            } else {
                console.error("Failed to fetch data:", response.statusText);
                alert("Infoseed 페이지 연동에 실패하였습니다.");
            }
        } catch (err) {
            console.error("Error fetching Infoseed page:", err);
            alert("페이지 연동에 실패하였습니다.");
        }
    } else {
        const serviceWindow = window.open(windowUrl, windowName);
        if (!serviceWindow || serviceWindow.closed) {
            alert("팝업 차단이 활성화되어 있습니다. 팝업을 허용해 주세요.");
            return;
        }

        submitForm(formActionUrl, windowName, updatedFormInputs);
    }
};

// Mapprime 페이지 열기
export const openMapprimePage = async () => {
    await openServicePage(
        "Mapprime",
        "https://manager.mapprime.co.kr",
        `${config.mapprimeApiUrl}/${config.mapprimeApiVer}/gate-m`,
        { t: "", r: "" },
        "manager-window"
    );
};

// Neospectra 페이지 열기
export const openNeospectraPage = async () => {
    await openServicePage(
        "Neospectra",
        "https://neospectra.example.com",
        `${config.neospectraApiUrl}/redirect/${config.neospectraApiVer}/`,
        { access_token: "", refresh_token: "", target: "home" },
        "neospectra-window"
    );
};

// Infoseed 페이지 열기
// export const openInfoseedPage = async () => {
//     await openServicePage(
//         "Infoseed",
//         "https://saas.geonick.name",
//         `${config.infoseedApiUrl}/v1/page`,
//         {
//             ptoken: "",
//             apikey: config.infoseedApiKey,
//         },
//         "infoseed-window"
//     );
// };

// ptoken 넣기
export async function openInfoseedPage() {
    const pageUrl = config.infoseedApiUrl + "/v1/page";
    const infoseed_token = localStorage.getItem('infoseed_token');

    try {
        const response = await fetch(pageUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${infoseed_token}`,
                PAGE_CD: "P001",
            },
        });

        if (response.ok) {
            const access_token  = localStorage.getItem('access_token');
            const data = await response.json();
            // console.log('info api 리턴 결과:', JSON.stringify(data, null, 2));

            // 동적 폼 생성
            const form = document.createElement('form');
            form.method = 'GET';
            form.action = data.data.URL; // API에서 반환된 URL
            form.target = 'infoseed-window'; // 새 창에서 열기

            // 필요한 데이터를 hidden input으로 추가
            const tokenInput = document.createElement('input');
            tokenInput.type = 'hidden';
            tokenInput.name = 'ptoken';
            tokenInput.value = access_token!;
            form.appendChild(tokenInput);

            const pageCdInput = document.createElement('input');
            pageCdInput.type = 'hidden';
            pageCdInput.name = 'apikey';
            pageCdInput.value = config.infoseedApiKey;
            form.appendChild(pageCdInput);

            // 폼 제출
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
    } catch (e) {
        alert("페이지 연동시 오류가 발생하였습니다.");
    }
}

// ptoken 넣기
export async function openInfoProject(pageNo: string, projId: string) {
    const pageUrl = config.infoseedApiUrl + "/v1/page";
    const infoseed_token = localStorage.getItem('infoseed_token');

    try {
        const response = await fetch(pageUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${infoseed_token}`,
                PAGE_CD: pageNo,
                // PRJ_NID: projId
            },
        });

        if (response.ok) {
            const access_token  = localStorage.getItem('access_token');
            const data = await response.json();
            // console.log('info api 리턴 결과:', JSON.stringify(data, null, 2));

            // 동적 폼 생성
            const form = document.createElement('form');
            form.method = 'GET';
            form.action = data.data.URL; // API에서 반환된 URL
            form.target = 'infoseed-window'; // 새 창에서 열기

            // 필요한 데이터를 hidden input으로 추가
            const tokenInput = document.createElement('input');
            tokenInput.type = 'hidden';
            tokenInput.name = 'ptoken';
            tokenInput.value = access_token!;
            form.appendChild(tokenInput);

            const pageCdInput = document.createElement('input');
            pageCdInput.type = 'hidden';
            pageCdInput.name = 'apikey';
            pageCdInput.value = config.infoseedApiKey;
            form.appendChild(pageCdInput);

            // 폼 제출
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
    } catch (e) {
        alert("페이지 연동시 오류가 발생하였습니다.");
    }
}