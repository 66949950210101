import { FC } from 'react';
import { Link } from "react-router-dom";
import "../../styles/layout/footer-menu.scss";

const Menu:FC = () => {
    return (
        <ul className="footer--menu--ul">
            <li><Link to={'/privacy'}><b>개인정보처리방침</b></Link></li>
            <li><Link to={'/term'}>이용약관</Link></li>
            <li><Link to={'/sla'}>맵프라임클라우드 서비스 수준 협약</Link></li>
            <li><Link to={'/email'}>이메일주소무단수집거부</Link></li>
        </ul>
    )
}
export default Menu;