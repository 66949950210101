import axios from 'axios';
import config from '../config';
import { getProfile, logout, refreshToken, getMapprimeToken, getInfoseedToken } from './authuser';

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


// 포탈 API 설정
const accessTokenPortalApi = axios.create({
    baseURL: config.portalApiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 맵프라임 API 설정
export const accessTokenMapprimeApi = axios.create({
    baseURL: config.mapprimeApiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 네오스펙트라 API 설정
export const accessTokenNeospectraApi = axios.create({
    baseURL: config.neospectraApiUrl,
    headers: {
        'Content-Type': 'application/json',
        'x-ncp-apigw-api-key': config.neospectraApiKey
    },
});

// 인포시드 API 설정
export const accessTokenInfoseedApi = axios.create({
    baseURL: config.infoseedApiUrl,
    headers: {
        'Content-Type': 'application/json',
        // 'apikey': config.infoseedApiKey
    },
});

// 포탈 API 요청 인터셉터
accessTokenPortalApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



// 포탈 API 응답 인터셉터
accessTokenPortalApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        // console.log("error: ", error.response.status);
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = `Bearer ${token}`
                        return accessTokenPortalApi(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // console.log('토큰 인증 실패: 401 에러 발생');
            const refreshExpiresIn = localStorage.getItem('refresh_token_expires_in');
            const expiresIn = localStorage.getItem('access_token_expires_in');
            const _refreshToken = localStorage.getItem('refresh_token');

            if (_refreshToken && expiresIn && refreshExpiresIn) {
                const refreshExpirationDate = new Date(refreshExpiresIn);
                const expirationDate = new Date(expiresIn);
                const currentDate = new Date(); // 현재 시간을 Date 객체로 가져옴

                // 현재 시간이 만료 시간을 지났는지 확인
                if (currentDate > expirationDate && currentDate < refreshExpirationDate) {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const newToken = await refreshToken(_refreshToken!);
                            if (newToken?.status === 'success' && newToken.data) {
                                const updatedAccessToken = newToken!.data.access_token;
                                localStorage.setItem('access_token', updatedAccessToken);
                                localStorage.setItem('refresh_token', newToken!.data.refresh_token);
                                localStorage.setItem('access_token_expires_in', newToken!.data.access_token_expires_in);
                                localStorage.setItem('refresh_token_expires_in', newToken!.data.refresh_token_expires_in);
                                accessTokenPortalApi.defaults.headers['Authorization'] = 'Bearer ' + updatedAccessToken;
                                originalRequest.headers['Authorization'] = `Bearer ${updatedAccessToken}`;
                                processQueue(null, newToken!.data.access_token);
                                resolve(accessTokenPortalApi(originalRequest));
                                await getMapprimeToken();
                                await getInfoseedToken();
                                const mapprimeToken = localStorage.getItem('mapprimeToken');
                                accessTokenMapprimeApi.defaults.headers['Authorization'] = `Bearer ${mapprimeToken}`;
                                accessTokenNeospectraApi.defaults.headers['Authorization'] = `Bearer ${newToken!.data.access_token}`;
                                const infoseedToken = localStorage.getItem('infoseed_token');
                                accessTokenInfoseedApi.defaults.headers['Authorization'] = `Bearer ${infoseedToken}`;
                            } else {
                                alert('토큰 인증이 실패하였습니다.');
                                await logout(localStorage.getItem('access_token'));
                                window.location.href = '/login';
                                reject(new Error('Token refresh failed'));
                            }
                        } catch (err) {
                            processQueue(err, null);
                            await logout(localStorage.getItem('access_token'));
                            window.location.href = '/login';
                            reject(err);
                        } finally {
                            isRefreshing = false;
                        }
                    });
                } else {
                    alert("로그인 세션이 종료되었습니다. 보안을 위해 다시 로그인해 주세요.");
                    await logout(localStorage.getItem('access_token'));
                    window.location.href = "/login";
                    return Promise.reject(error);
                }
            } else {
                // console.log("만료 시간이 설정되어 있지 않습니다.");
            }
        }

        return Promise.reject(error);
    }
);



// 요청 인터셉터 설정 (모든 요청에 토큰을 포함)
accessTokenMapprimeApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('mapprimeToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 설정 (토큰 만료 시 처리)
accessTokenMapprimeApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`
                    return accessTokenPortalApi(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // console.log('맵프라임 토큰 인증 실패: 401 에러 발생');
            const refreshExpiresIn = localStorage.getItem('refresh_token_expires_in');
            const expiresIn = localStorage.getItem('access_token_expires_in');
            const _refreshToken = localStorage.getItem('refresh_token');

            if (_refreshToken && expiresIn && refreshExpiresIn) {
                const refreshExpirationDate = new Date(refreshExpiresIn);
                const expirationDate = new Date(expiresIn);
                const currentDate = new Date(); // 현재 시간을 Date 객체로 가져옴
                if (currentDate > expirationDate && currentDate < refreshExpirationDate) {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const newToken = await refreshToken(_refreshToken!);
                            if (newToken?.status === 'success' && newToken.data) {
                                localStorage.setItem('access_token', newToken.data.access_token);
                                localStorage.setItem('refresh_token', newToken.data.refresh_token);
                                localStorage.setItem('access_token_expires_in', newToken.data.access_token_expires_in);
                                localStorage.setItem('refresh_token_expires_in', newToken.data.refresh_token_expires_in);
                                accessTokenPortalApi.defaults.headers['Authorization'] = 'Bearer ' + newToken;
                                await getMapprimeToken();
                                await getInfoseedToken();
                                const mapprimeToken = localStorage.getItem('mapprimeToken');
                                processQueue(null, mapprimeToken);
                                resolve(accessTokenMapprimeApi(originalRequest));
                                accessTokenMapprimeApi.defaults.headers['Authorization'] = `Bearer ${mapprimeToken}`;
                                accessTokenNeospectraApi.defaults.headers['Authorization'] = `Bearer ${newToken!.data.access_token}`;
                                const infoseedToken = localStorage.getItem('infoseed_token');
                                accessTokenInfoseedApi.defaults.headers['Authorization'] = `Bearer ${infoseedToken}`;
                            } else {
                                alert('토큰 인증이 실패하였습니다.');
                                await logout(localStorage.getItem('access_token'));
                                window.location.href = '/login';
                                reject(new Error('Token refresh failed'));
                            }
                        } catch (err) {
                            processQueue(err, null);
                            await logout(localStorage.getItem('access_token'));
                            window.location.href = '/login';
                            reject(err);
                        } finally {
                            isRefreshing = false;
                        }
                    });
                } else {
                    alert("로그인 세션이 종료되었습니다. 보안을 위해 다시 로그인해 주세요.");
                    await logout(localStorage.getItem('access_token'));
                    window.location.href = "/login";
                    return Promise.reject(error);
                }
            } else {
                // console.log("만료 시간이 설정되어 있지 않습니다.");
            }
        } 

        return Promise.reject(error);
    }
);


// 요청 인터셉터 설정 (모든 요청에 토큰을 포함)
accessTokenNeospectraApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 설정 (토큰 만료 시 처리)
accessTokenNeospectraApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = `Bearer ${token}`
                        return accessTokenPortalApi(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // console.log('토큰 인증 실패: 401 에러 발생');
            const refreshExpiresIn = localStorage.getItem('refresh_token_expires_in');
            const expiresIn = localStorage.getItem('access_token_expires_in');
            const _refreshToken = localStorage.getItem('refresh_token');

            if (_refreshToken && expiresIn && refreshExpiresIn) {
                const refreshExpirationDate = new Date(refreshExpiresIn);
                const expirationDate = new Date(expiresIn);
                const currentDate = new Date(); // 현재 시간을 Date 객체로 가져옴

                if (currentDate > expirationDate && currentDate < refreshExpirationDate) {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const newToken = await refreshToken(_refreshToken!);
                            if (newToken?.status === 'success' && newToken.data) {
                                localStorage.setItem('access_token', newToken.data.access_token);
                                localStorage.setItem('refresh_token', newToken.data.refresh_token);
                                localStorage.setItem('access_token_expires_in', newToken!.data.access_token_expires_in);
                                localStorage.setItem('refresh_token_expires_in', newToken!.data.refresh_token_expires_in);
                                accessTokenPortalApi.defaults.headers['Authorization'] = 'Bearer ' + newToken.data.access_token;
                                originalRequest.headers['Authorization'] = `Bearer ${newToken.data.access_token}`;
                                processQueue(null, newToken.data.access_token);
                                resolve(accessTokenNeospectraApi(originalRequest));
                                await getMapprimeToken();
                                await getInfoseedToken();
                                const mapprimeToken = localStorage.getItem('mapprimeToken');
                                accessTokenMapprimeApi.defaults.headers['Authorization'] = `Bearer ${mapprimeToken}`;
                                accessTokenNeospectraApi.defaults.headers['Authorization'] = `Bearer ${newToken!.data.access_token}`;
                                const infoseedToken = localStorage.getItem('infoseed_token');
                                accessTokenInfoseedApi.defaults.headers['Authorization'] = `Bearer ${infoseedToken}`;
                            } else {
                                alert('토큰 인증이 실패하였습니다.');
                                await logout(localStorage.getItem('access_token'));
                                window.location.href = '/login';
                                reject(new Error('Token refresh failed'));
                            }
                        } catch (err) {
                            processQueue(err, null);
                            await logout(localStorage.getItem('access_token'));
                            window.location.href = '/login';
                            reject(err);
                        } finally {
                            isRefreshing = false;
                        }
                    });
                } else {
                    alert("로그인 세션이 종료되었습니다. 보안을 위해 다시 로그인해 주세요.");
                    await logout(localStorage.getItem('access_token'));
                    window.location.href = "/login";
                    return Promise.reject(error);
                }
            } else {
                // console.log("만료 시간이 설정되어 있지 않습니다.");
            }
        }

        return Promise.reject(error);
    }
);

// 요청 인터셉터 설정 (모든 요청에 토큰을 포함)
accessTokenInfoseedApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('infoseed_token');
        if (token) {
            config.headers['IFTOKEN'] = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 설정 (토큰 만료 시 처리)
accessTokenInfoseedApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`
                    return accessTokenPortalApi(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // console.log('인포시드 인증 실패: 401 에러 발생');
            const refreshExpiresIn = localStorage.getItem('refresh_token_expires_in');
            const expiresIn = localStorage.getItem('access_token_expires_in');
            const _refreshToken = localStorage.getItem('refresh_token');

            if (_refreshToken && expiresIn && refreshExpiresIn) {
                const refreshExpirationDate = new Date(refreshExpiresIn);
                const expirationDate = new Date(expiresIn);
                const currentDate = new Date(); // 현재 시간을 Date 객체로 가져옴

                if (currentDate > expirationDate && currentDate < refreshExpirationDate) {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const newToken = await refreshToken(_refreshToken!);

                            if (newToken?.status === 'success' && newToken.data) {
                                localStorage.setItem('access_token', newToken!.data.access_token);
                                localStorage.setItem('refresh_token', newToken!.data.refresh_token);
                                localStorage.setItem('access_token_expires_in', newToken!.data.access_token_expires_in);
                                localStorage.setItem('refresh_token_expires_in', newToken!.data.refresh_token_expires_in);
                                accessTokenPortalApi.defaults.headers['Authorization'] = 'Bearer ' + newToken!.data.access_token;
                                await getMapprimeToken();
                                await getInfoseedToken();
                                const mapprimeToken = localStorage.getItem('mapprimeToken');
                                accessTokenMapprimeApi.defaults.headers['Authorization'] = `Bearer ${mapprimeToken}`;
                                accessTokenNeospectraApi.defaults.headers['Authorization'] = `Bearer ${newToken}`;
                                const infoseedToken = localStorage.getItem('infoseed_token');
                                processQueue(null, infoseedToken);
                                accessTokenInfoseedApi.defaults.headers['Authorization'] = `Bearer ${infoseedToken}`;
                                originalRequest.headers['IFTOKEN'] = `${infoseedToken}`;
                                resolve(accessTokenInfoseedApi(originalRequest));
                            } else {
                                alert('토큰 인증이 실패하였습니다.');
                                await logout(localStorage.getItem('access_token'));
                                window.location.href = '/login';
                            }

                        } catch (err) {
                            processQueue(err, null);
                            await logout(localStorage.getItem('access_token'));
                            window.location.href = '/login';
                            reject(err);
                        } finally {
                            isRefreshing = false;
                        }
                    });
                } else {
                    alert("로그인 세션이 종료되었습니다. 보안을 위해 다시 로그인해 주세요.");
                    await logout(localStorage.getItem('access_token'));
                    window.location.href = "/login";
                    return Promise.reject(error);
                }
            } else {
            // console.log("만료 시간이 설정되어 있지 않습니다.");
            }
        }

        return Promise.reject(error);
    }
);

export default accessTokenPortalApi;
