import { FC, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Banners from "../../containers/banner";
import TabComponent from '../../components/Tab';
import './product.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import Product from '../../components/Product'
import { loadTossPayments, ANONYMOUS , TossPaymentsWidgets } from "@tosspayments/tosspayments-sdk";
import { CreateBuyDto } from "../../types/CreateBuyDto";
import { useNavigate } from 'react-router-dom';
import { postBuyAsync , getMapprimeDashboardAsync , getBuyUseAsync , getBuyUseListAsync } from '../../modules/product';
import { useQuery } from '../common/stringUtil';
import ProjectManage1 from "../mypage/ProjectManage1";
import ProjectManage2 from "../mypage/ProjectManage2";
import ProjectManage8 from "../mypage/ProjectManage8";
import InfoManage from "./InfoManage";
import { getNeospectraUsagesCurrentAsync , getNeospectraProjectCntAsync , getInfoseedMypageAsync } from '../../modules/myPage';
import { getBytesToSize , getFormatCurrency } from "../../pages/common/stringUtil";

interface PageProps {
    title: string;
}

interface totalDataMapProps {
	useData: string;
	totalData: string;
	usePer:number;
	useDataNumber : number;
	totalDataNumber : number;
}

const tabs = [
    { id: 1, title: '영상촬영 서비스'           , control: 'content-1' , key: 'GEO_NIC' },
    { id: 2, title: 'AI 데이터 생성관리 서비스' , control: 'content-2' , key: 'SIMMETA' },
    { id: 3, title: '디지털트윈 지도 서비스'    , control: 'content-3' , key: 'MAPPRIME' },
];

const ProductGuide: FC<PageProps> = ({ title }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const query = useQuery();
    const tabSn = Number(query.get('tab')); // 쿼리 파라미터에서 id 값을 가져옵니다.

    const [currentTab, setCurrentTab] = useState<number>(tabs[tabSn].id);
    const [sltnId, setSltnId]   = useState<string>(tabs[tabSn].key);
    const [buyYn, setBuyYn]     = useState<string>("none");
    const [buyType, setBuyType] = useState<string>("VIEW");
    const [widgets, setWidgets] = useState<TossPaymentsWidgets | null>(null);

    const buyInfo = useSelector((state:RootState) => state.product.buyInfo);
    const [buyUserArr , setBuyUserArr] = useState<any[]>([]);
    const buyUserList = useSelector((state:RootState) => state.product.buyUserList);
    const mapprimeDashboard = useSelector((state:RootState) => state.product.dashboardInfo);
    const neoUsagesCurrent = useSelector((state:RootState) => state.myPage.neoUsagesCurrent);
    const neoProjCnt       = useSelector((state:RootState) => state.myPage.neoProjCnt);
	const infoseedMypage = useSelector((state:RootState) => state.myPage.infoseedMypage);
    const [projectCntDataMap, setProjectCntDataMap] = useState<any>();
    const [infoDataMap, setInfoDataMap] = useState<any>();

    const [createBuyDto, setCreateBuyDto] = useState<CreateBuyDto>({
        stlmAmt : 0,
        customerMobilePhone : ""
    });

    const [totalDataMap1, setTotalDataMap1] = useState<totalDataMapProps>({
        useData : "0GB",
        totalData : "0GB",
		usePer : 0.0,
		useDataNumber : 0,
		totalDataNumber : 0,

    });
	const [totalDataMap2, setTotalDataMap2] = useState<totalDataMapProps>({
        useData : "0GB",
        totalData : "0GB",
		usePer : 0.0,
		useDataNumber : 0,
		totalDataNumber : 0,
    });
	const [totalDataMap3, setTotalDataMap3] = useState<totalDataMapProps>({
        useData : "0GB",
        totalData : "0GB",
		usePer : 0.0,
		useDataNumber : 0,
		totalDataNumber : 0,
    });

    const handleTabClick = (id: number , title:string) => {

        setSltnId(tabs[id-1].key);

        setCurrentTab(id);

        setBuyYn("none");
    };

    useEffect(() => {
        const fetchBuyUser = async () => {
            await dispatch(getBuyUseListAsync.request(""));
        };

        fetchBuyUser();
    }, []);

    useEffect(() => {
        if (buyUserList && buyUserList.data && buyUserList.data.data) {
            const fetchBuyUser = async () => {
                await setBuyUserArr(buyUserList.data.data);
            }
			
            fetchBuyUser();
	
			// console.log('buyUser 리턴 결과:', JSON.stringify(buyUserArr, null, 2));
		}
    }, [buyUserList]);

    useEffect(() => {
        if (buyUserArr && buyUserArr.length > 0) {
            const fetchBuyUser = async () => {
                if(localStorage.getItem("isMapprimeSubscribe")) {
                    await dispatch(getMapprimeDashboardAsync.request({
                        mapprimeToken: localStorage.getItem("mapprimeToken"),
                    }));
                }
                
                if(localStorage.getItem("isInfoseedSubscribe")) {
                    await dispatch(getInfoseedMypageAsync.request(null));
                }

                if(localStorage.getItem("isNeospectraSubscribe")) {
                    await dispatch(getNeospectraProjectCntAsync.request({
                        access_token: localStorage.getItem("access_token"),
                    }));

                    await dispatch(getNeospectraUsagesCurrentAsync.request({
                        access_token: localStorage.getItem("access_token"),
                    }));
                }
            }
			
            fetchBuyUser();
	
		}
    }, [buyUserArr]);

    

    useEffect(() => {
        if (infoseedMypage && infoseedMypage.data && infoseedMypage.data.status != "error") {
            const buyUserInfo = buyUserArr.filter((item : any) => item.sltnId === "GEO_NIC");

			const prdctId = (buyUserInfo && buyUserInfo.length > 0) ? buyUserInfo[0].prdctId : "";

			
			// const totalData = Number(infoseedMypage.data.data.TOTAL_MEMORY);

			var totalData = 0;
			if (prdctId == "PRDCT_GRP001") {
				totalData = 0;
			} else if (prdctId == "PRDCT_GRP002") {
				totalData = 10*1024;
			} else if (prdctId == "PRDCT_GRP003") {
				totalData = 20*1024;
			} else if (prdctId == "PRDCT_GRP004") {
				totalData = 30*1024;
			}

			// const totalData = Number(infoseedMypage.data.data.TOTAL_MEMORY);
			const currentData = Number(infoseedMypage.data.data.USE_MEMORY);

			const dataMap = {
				totalData : getBytesToSize(totalData*1024*1024,2),
				useData   : getBytesToSize(currentData*1024*1024,2),
				usePer : totalData == 0 ? 0 : Math.round(currentData/totalData*100),
				useDataNumber : currentData*1024*1024,
				totalDataNumber : totalData*1024*1024,
			}

			// console.log('infoseedMypage 리턴 결과:', JSON.stringify(infoseedMypage, null, 2));
			
			setTotalDataMap1(dataMap);

            setInfoDataMap(infoseedMypage.data.data);

		}
    }, [infoseedMypage]);

	useEffect(() => {

        if (neoUsagesCurrent && neoUsagesCurrent.data && neoUsagesCurrent.data.result != "error") {
            const buyUserInfo = buyUserArr.filter((item : any) => item.sltnId === "SIMMETA");
           	const prdctId = (buyUserInfo && buyUserInfo.length > 0) ? buyUserInfo[0].prdctId : "";
            
            var totalData = 0;
            if (prdctId == "PRDCT_GRP001") {
                totalData = 3;
            } else if (prdctId == "PRDCT_GRP002") {
                totalData = 100;
            } else if (prdctId == "PRDCT_GRP003") {
                totalData = 300;
            } else if (prdctId == "PRDCT_GRP004") {
                totalData = 1000;
            }
			
			// const totalData = Number(neoUsagesCurrent.data?.available);
			const currentData = Number(neoUsagesCurrent.data?.used);

			if (totalData) {
				const dataMap = {
					totalData : getFormatCurrency(totalData)+"GB",
				    useData   : getFormatCurrency(currentData)+"GB",
					usePer : Math.round(currentData/totalData*100),
					useDataNumber : currentData*1024*1024*1024,
					totalDataNumber : totalData*1024*1024*1024,
				}
				
				setTotalDataMap2(dataMap);
			} else {
				const dataMap = {
					totalData : "0",
					useData : "0",
					usePer : 0,
					useDataNumber : 0,
					totalDataNumber : 0,
				}
				
				setTotalDataMap2(dataMap);
			}
		}
    }, [neoUsagesCurrent]);

    useEffect(() => {
        if (mapprimeDashboard && mapprimeDashboard.data && mapprimeDashboard.data.result != "error") {
			
            const buyUserInfo = buyUserArr.filter((item : any) => item.sltnId === "MAPPRIME");

			const prdctId = (buyUserInfo && buyUserInfo.length > 0) ? buyUserInfo[0].prdctId : "";


			var totalData = 0;
			if (prdctId == "PRDCT_GRP001") {
				totalData = 5*1024*1024*1024;
			} else if (prdctId == "PRDCT_GRP002") {
				totalData = 50*1024*1024*1024;
			} else if (prdctId == "PRDCT_GRP003") {
				totalData = 1000*1024*1024*1024;
			} else if (prdctId == "PRDCT_GRP004") {
				totalData = 2000*1024*1024*1024;
			}
			
			// const totalData = Number(mapprimeDashboard.data.size.total);
			const currentData = Number(mapprimeDashboard.data.size.current);
			const usePer = (currentData / totalData) * 100;

			const dataMap = {
				totalData : getBytesToSize(totalData,3),
				useData : getBytesToSize(currentData,3),
				usePer : Number(usePer.toFixed(2)),
				useDataNumber : currentData,
				totalDataNumber : totalData,
			}
			
			setTotalDataMap3(dataMap);

            setProjectCntDataMap(mapprimeDashboard.data.count);
		}
    }, [mapprimeDashboard]);

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');
        const userRoleCd   = localStorage.getItem('userRoleCd');

        var buyUser = [] ;
        if (buyUserArr && buyUserArr.length > 0) {
            buyUser = buyUserArr.filter((item : any) => item.sltnId === sltnId);
        }

        if (buyUser.length > 0) {
            setBuyType("VIEW");
        } else {
            if (access_token && userRoleCd && userRoleCd == "ADMIN") {
                setBuyType("BUY");
            } else {
                setBuyType("VIEW");
            }
        }
    }, [sltnId,buyUserArr]);

    const setBuyProduct = (prdctSn: number , prdctNm:string , month : number , payment : number , widgets : TossPaymentsWidgets | null, customerKey : string) => {
       
        setWidgets(widgets);

        var scrtnTypeCd = "M"; 
        if (month == 12) {
            scrtnTypeCd = "Y"; 
        }

        const access_token = localStorage.getItem('access_token');
        const userRoleCd   = localStorage.getItem('userRoleCd');

        var buyUser = [] ;
        if (buyUserArr && buyUserArr.length > 0) {
            buyUser = buyUserArr.filter((item : any) => item.sltnId === sltnId);
        }

        if (buyUser.length > 0) {
            setBuyYn("none");
        } else {
            if (access_token && userRoleCd && userRoleCd == "ADMIN" && payment > 0) {
                setBuyYn("block");
            } else {
                setBuyYn("none");
            }

            setCreateBuyDto((prev) => ({ ...prev,
                stlmNm : prdctNm
              , prdctSn: prdctSn
              , scrtnTypeCd :scrtnTypeCd
              , stlmAmt : payment
              , stlmCustKey : customerKey
          }));
        }
    }

    // input 객체 변경시 
    const handleKeyUp = (event:React.KeyboardEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        input.value = input.value.replace(/[^0-9]/g, '');
    };

    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCreateBuyDto((prev) => ({ ...prev, [name]: value}));
    };

    const buyAct = () => {
        
        const access_token = localStorage.getItem('access_token');
        
        if (!access_token) {
            alert("로그인 후 결제가 가능합니다.");
            navigate('/login') 
        }
       
        if (createBuyDto == null) {
            alert("구매할 상품을 선택해 주십시오.");
        } else if (createBuyDto.prdctSn == null || createBuyDto.prdctSn == 0) {
            alert("구매할 상품을 선택해 주십시오.");
        } else if (createBuyDto.stlmAmt > 0 && (createBuyDto.customerMobilePhone == null || createBuyDto.customerMobilePhone == "")) {
            alert("결제 결과를 받을 핸드폰 번호를 입력해 주십시오.");
        } else if (createBuyDto.stlmAmt == 0) {
            navigate('/contact') 
        } else {
            
            const fetchBuyInfo = async () => {
                await dispatch(postBuyAsync.request(createBuyDto)); 
            };
    
            fetchBuyInfo();
        }
    }

    useEffect(() => {
        // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
        // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
        try {
            // console.log('createBuyDto parameter 결과:', JSON.stringify(createBuyDto, null, 2));

            if (widgets != null) {
                if (buyInfo && buyInfo.data ) {
                    
                    if (buyInfo.data.status == "error") {
                        if (buyInfo.data.error) {
                            alert(buyInfo.data.error.message);
                            return;
                        }
                    }
                    
                    
                    const orderId: string | undefined = buyInfo.data.data.stlmId as string;

                    if (createBuyDto) {
                        widgets.requestPayment({
                            orderId: orderId,
                            orderName: buyInfo.data.data.stlmNm,
                            successUrl: window.location.origin + "/buySuccess",
                            failUrl: window.location.origin + "/buyFail",
                            customerEmail: buyInfo.data.data.customerEmail,
                            customerName: buyInfo.data.data.customerName,
                            customerMobilePhone: createBuyDto.customerMobilePhone?.replaceAll("-",""),
                        });
                    }
                    
                }  
            }
        } catch (error) {
            // 에러 처리하기
            console.error(error);
        }
      }, [buyInfo]);


    return (
        <div className="features">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <TabComponent
                        tabs={tabs}
                        role='tab'
                        current={currentTab}
                        onClick={(index, tab) => handleTabClick(tab.id,tab.title)}
                    />
                    <div className="tabpanel" id='content-1' role='tabpanel' hidden={currentTab !== 1}>
                        <dl className='product-dl mb50'>
                            <dt>고객님과 함께하는 <span>요금제</span></dt>
                            <dd>
                                비행촬영 및 현장조사사진 촬영과 관리를 위한 서비스를 소개합니다.
                            </dd>
                        </dl>
                        {localStorage.getItem("isInfoseedSubscribe") ? (
                            <div style={{marginBottom : "20px"}}>
                                <div className="grid">
                                    <ProjectManage1 tabSn={0} sltnId={tabs[0].key} buyUserList={buyUserArr} totalDataMap={totalDataMap1} />
                                    <InfoManage infoDataMap = {infoDataMap} />
                                </div>
                            </div>
                        ) : null}

                        
                    </div>
                    <div className="tabpanel" id='content-2' role='tabpanel' hidden={currentTab !== 2}>
                        <dl className='product-dl mb50'>
                            <dt>고객님과 함께하는 <span>요금제</span></dt>
                            <dd>
                                기업의 업무 환경에 맞게 사용할 수 있도록 다양한 상품을 제공합니다.
                            </dd>
                        </dl>

                        {localStorage.getItem("isNeospectraSubscribe") ? (
                        <div style={{marginBottom : "20px"}}>
                            <div className="grid">
                                <ProjectManage1 tabSn={1} sltnId={tabs[1].key} buyUserList={buyUserArr} totalDataMap={totalDataMap2} />
                                <ProjectManage8 neoProjCnt = {neoProjCnt.data} />
                            </div>
                        </div>
                        ) : null }

                    </div>
                    <div className="tabpanel" id='content-3' role='tabpanel' hidden={currentTab !== 3}>
                        <dl className='product-dl mb50'>
                            <dt>고객님과 함께하는 <span>요금제</span></dt>
                            <dd>
                                영상정보 관리 및 분석 서비스를 위한 다양한 모델을 빠르고 효과적으로 구축할 수 있는 서비스를 소개합니다.
                            </dd>
                        </dl>

                        {localStorage.getItem("isMapprimeSubscribe") ? (
                            <div style={{marginBottom : "20px"}}>
                                <div className="grid">
                                    <ProjectManage1 tabSn={2} sltnId={tabs[2].key} buyUserList={buyUserArr} totalDataMap={totalDataMap3} />
                                    <ProjectManage2 projectCntDataMap={projectCntDataMap} />
                                </div>
                            </div>
                        ) : null
                        }

                    </div>
                    
                    <Product sltnId={sltnId} compAmt={0} buyPrdctId="" buyType={buyType} setBuyProduct={setBuyProduct}  />

                    {/* 결제 UI */}
                    
                    
                        <div style={{display:buyYn}}>
                            <div id="payment-method" style={{display: createBuyDto.stlmAmt > 0 ? "" : "none" }} />
                            {createBuyDto.stlmAmt > 0 ? (
                            <div id="inputPhone" style={{display: createBuyDto.stlmAmt > 0 ? "flex" : "none" }}>
                                <div style={{paddingLeft:"30px",paddingTop: "13px"}}>
                                    <span style={{color:"red"}}><b> * </b></span>핸드폰 번호
                                </div>
                                <div style={{paddingLeft:"10px"}}>
                                    <input type="number" className="form-control " id="customerMobilePhone" placeholder="핸드폰 번호를 숫자만 입력해 주세요." name="customerMobilePhone" style={{width:"300px"}} value={createBuyDto.customerMobilePhone} onChange={inputHandleChange} onKeyUp={handleKeyUp} required />
                                </div>
                            </div>) : null }
                            {/* 이용약관 UI */}
                            <div id="agreement" style={{display: createBuyDto.stlmAmt > 0 ? "" : "none" }} />
                            <div className="flex justify-center" style={{marginTop: createBuyDto.stlmAmt > 0 ? "0px" : "50px" }}>
                                <button type="button" className="button button--outline-black button--round button--md" style={{ marginRight: "40px" }} onClick={buyAct}>{createBuyDto.stlmAmt > 0 ? "구매 하기" : "영업팀에 문의하기" }</button>
                            </div>
                        </div>
                    
            </div>
        </div>
    </div>
    );
};

export default ProductGuide;
