import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import Progress from "./Progress";

interface ProjectState {
    id : string;
    title : string;
    count : number;
    theme : string;
}

const projects: ProjectState[] = [
    { id: "ProjectCount" , title: "프로젝트" , count: 10 , theme : 'blue'},
    { id: "AssetsCount"  , title: "에셋"    , count: 20 , theme : 'sky'},
    { id: "DataCount"    , title: "이미지"  , count: 30 , theme : 'darksky'},
]


const ProjectManage7:FC<any> = (  projectDataMap ) => {

    const totalProjectCnt = projectDataMap.projectCntDataMap?.project?.total;
    const projectCnt      = projectDataMap.projectCntDataMap?.project?.current;
    const totalAssetCnt   = projectDataMap.projectCntDataMap?.asset?.total;
    const assetCnt        = projectDataMap.projectCntDataMap?.asset?.progress + projectDataMap.projectCntDataMap?.asset?.done;

    const projectPer = (Number(totalProjectCnt) == 0 ? 0 : Math.floor(Number(projectCnt) / (Number(totalProjectCnt)) * 100)) ;
    const assetPer   = (Number(totalAssetCnt)   == 0 ? 0 : Math.floor(Number(assetCnt) / (Number(totalAssetCnt)) * 100)) ;

    return (
        <ProjectSections title="프로젝트, 에셋 현황" className="col-12 col-lg-8 col-md-12 user-state" >
            <Progress 
                item={{ id: "ProjectCount", title: "프로젝트", count: (projectPer ? projectPer : 0) , text : projectCnt }} 
                theme="blue"
            />
            <Progress 
                    item={{ id: "AssetsCount", title: "에셋", count: (assetPer ? assetPer : 0) , text : (assetCnt ? assetCnt : 0) }} 
                    theme="sky"
            />

        </ProjectSections>
    )
}
export default ProjectManage7;
