import { FC , useEffect , useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/components/icon.scss";
import "../../styles/layout/breadcrumb.scss";

const Breadcrumb: FC = () => {
    const topMenu = [
        { id: 100, title: '서비스 소개', pageUrl: '/overview' },
        { id: 200, title: '제품 안내', pageUrl: '/product' },
        { id: 300, title: '프로젝트', pageUrl: '/project' },
        { id: 400, title: '고객지원', pageUrl: '/notice' },
        { id: 500, title: '마이페이지', pageUrl: '/mypage/project' },
    ];

    const subMenu = [
        { id: 110, title: '서비스 개요', pageUrl: '/overview', parentId: 100, view: true },
        { id: 120, title: '주요 기능', pageUrl: '/features', parentId: 100, view: true },

        { id: 210, title: '제품 안내', pageUrl: '/product', parentId: 200, view: true },

        { id: 310, title: '영상촬영 서비스'          , pageUrl: '/project?tab=2' , parentId: 300, view: true },
        { id: 320, title: 'AI 데이터 생성관리 서비스' , pageUrl: '/project?tab=3' , parentId: 300, view: true },
        { id: 330, title: '디지털트윈 지도 서비스'    , pageUrl: '/project?tab=4' , parentId: 300, view: true },

        { id: 430, title: '공지사항', pageUrl: '/notice', parentId: 400, view: true },
        { id: 430, title: 'FAQ', pageUrl: '/faq', parentId: 400, view: true },
        { id: 430, title: '문의하기', pageUrl: '/contact', parentId: 400, view: true },

        { id: 510, title: '나의 프로젝트', pageUrl: '/mypage/project', parentId: 500, view: true },
        { id: 510, title: '제품 선택', pageUrl: '/mypage/product', parentId: 500, view: false },
        { id: 520, title: '사용자 관리', pageUrl: '/mypage/MypageUserManage', parentId: 500, view: true },
        { id: 530, title: '나의 문의내역', pageUrl: '/mypage/MypageContactUs', parentId: 500, view: true },
        { id: 540, title: '회원정보수정', pageUrl: '/mypage/MypageUserUpdate', parentId: 500, view: true },
    ];

    const fullPathWithParams = window.location.pathname + window.location.search;

    const currentPath = window.location.pathname == '/project' ? fullPathWithParams : window.location.pathname;
    const [isExpanded, setIsExpanded] = useState(false);
    const pageSubMenu = subMenu.filter(item => item.pageUrl.indexOf(currentPath) == 0);

    var topMenuNm;
    var subMenuNm;
    var pageTopMenuList;
    var pageSubMenuList;

    if (pageSubMenu && pageSubMenu.length > 0) {
        const pageTopMenu = topMenu.filter(item => item.id === pageSubMenu[0].parentId);

        topMenuNm = (pageTopMenu && pageTopMenu.length > 0 ? pageTopMenu[0].title : "");

        var pageParentId = (pageSubMenu && pageSubMenu.length > 0 ? pageSubMenu[0].parentId : 0);
        subMenuNm = (pageSubMenu && pageSubMenu.length > 0 ? pageSubMenu[0].title : "");

        pageTopMenuList = topMenu.filter(item => item.id != pageParentId);
        pageSubMenuList = subMenu.filter(item => item.parentId === pageParentId && item.view && currentPath.indexOf(item.pageUrl) < 0);

        const userRoleCd = localStorage.getItem('userRoleCd');
 
        if (!userRoleCd) {
            pageTopMenuList = pageTopMenuList.filter(item => item.id != 500);
        }

        if (userRoleCd && userRoleCd != "ADMIN") {
            pageSubMenuList = pageSubMenuList.filter(item => item.id !== 520);
        }
    }

    const toggleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
        const button = e.currentTarget;
        const expanded = button.getAttribute('aria-expanded') === 'true';

        button.setAttribute('aria-expanded', expanded ? 'false' : 'true');
    };

    const toggleSubExpand = () => {
        if (isExpanded) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
        }
    };

    useEffect(() => {
        // toggleExpand();

        setIsExpanded(false);
        
    }, [subMenuNm]);
    return (
        <nav className="breadcrumbNav" aria-label="breadcrumb">
            {(topMenuNm) ? (
                <ol className="breadcrumb">
                    <li className="home">
                        <Link to={'/'} title="홈 바로가기">
                            <i className="icon--home" aria-hidden="true"></i>
                        </Link>
                    </li>
                    <li className="breadcrum--item--01">
                        <button type="button" className="drop-button" aria-expanded="false" onClick={toggleExpand}><span>{topMenuNm}</span>
                            <i className="icon--arw-white" aria-hidden="true"></i>
                        </button>
                        <ul className="drop-menu" style={{ zIndex: "99" }}>
                            {pageTopMenuList && pageTopMenuList.map((menu) => (
                                <li><Link to={menu.pageUrl}>{menu.title}</Link></li>
                            ))}
                        </ul>
                    </li>
                    <li className="breadcrum--item--02">
                        <button
                            type="button"
                            className="drop-button"
                            aria-expanded={isExpanded}
                            onClick={toggleSubExpand}
                            style={{width:topMenuNm == '프로젝트' ? "25rem" : "19rem"}}>
                            <span>{subMenuNm}</span>
                            
                            <i className="icon--arw-white" aria-hidden="true"></i>
                        </button>
                        <ul className="drop-menu" style={{ zIndex: "99" }}>
                            {pageSubMenuList && pageSubMenuList.map((menu) => (
                                <li><Link to={menu.pageUrl}>{menu.title}</Link></li>
                            ))}
                        </ul>
                    </li>
                </ol>
            ) : null}
        </nav>
    );
}

export default Breadcrumb;