import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import Progress from "./Progress";
import { openInfoPage }from "../common/stringUtil";
import PieChart from "../../components/PieChart";

type props = {
    infoPrjDataAmountMap: any[];
};

const ProjectManage14:FC<props> = ({  infoPrjDataAmountMap }) => {
    
    const projectDat : {name:string , value:number}[] = [];

    infoPrjDataAmountMap.map((project:any) => {
        projectDat.push({name : project.PRJ_NM  , value : project.USE_AMOUNT });
    });

    return (
        <ProjectSections title="프로젝트별 데이터 사용량" className="user-porject-data" onButtonClick={() => openInfoPage("P001")} buttonClassName="icon--more">
            <div style={{width : "20%",position:"absolute"}}>
            {infoPrjDataAmountMap && infoPrjDataAmountMap?.length > 0 ?
                <PieChart width={300} height={47*infoPrjDataAmountMap.length+57} data={projectDat} />
             :null}
            </div>

            <ul className='board-table user-tbl' style={{width : "70%",position:"relative" , left : "30%"}}>
                <li className="thead">
                    <span className='col-th'>프로젝트명</span>
                    <span className='col-th'>파일수(개)</span>
                    <span className='col-th'>사용 용량(MB)</span>
                </li>
							
                { infoPrjDataAmountMap && infoPrjDataAmountMap.length > 0 ? ( 
                    infoPrjDataAmountMap && infoPrjDataAmountMap.map((item: any, idx: number) => 

                <li className="tbody">
                    <span className='col-td' style={{verticalAlign:"middle"}}>{item.PRJ_NM}</span>
                    <span className='col-td' style={{verticalAlign:"middle"}}>{item.FILE_CNT}</span>
                    <span className='col-td' style={{verticalAlign:"middle"}}>{item.USE_AMOUNT}</span>
                </li>)
                ) : (<li className="empty"> 조회된 결과가 없습니다. </li>)
                }
            </ul>
        </ProjectSections>
    )
}
export default ProjectManage14;
