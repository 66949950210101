import React,{FC} from 'react';
import { BarChart, Bar, PieChart , Pie , Cell , ResponsiveContainer, Tooltip, Legend , Label } from 'recharts';

type ButtonProps = {
    width: number;
    height: number;
    data: any[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

interface CustomLabelProps {
  cx: number;
  cy: number;
  value: number | string;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ cx, cy, value }) => {
  return (
      <text x={cx} y={cy} textAnchor="middle" fill="#fff" fontSize={20}>
          {value}
      </text>
  );
};

const pieChart : FC<ButtonProps> = ({width,height,data}) => { 
    return (
      <div style={{ width: '100%', height: height }}>
        <ResponsiveContainer>
                <PieChart>
                    <Tooltip />
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={entry => entry.value}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                      {data.map((entry, index) => (
                          <Label
                              key={`label-${index}`}
                              value={entry.value}
                              position="center"
                              content={(props) => {
                                const { cx, cy } = props as { cx: number; cy: number; };
                                return <CustomLabel cx={cx} cy={cy} value={entry.value} />;
                            }}
                          />
                      ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
      </div>
        
      );
}
export default pieChart;